import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DossierService } from '@services/dossier.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersInfoService } from '@api-clients/user';
import { BuildingMenuService } from '@shared/services/building-menu.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TimelineDataSource } from './timeline-data-source';
import { BuildingOverviewService } from '@services/building-overview.service';
import {
  BimLink,
  ConstructionReport,
  DossierDocument,
  DossierFile,
  DossierImage,
  Location,
  Note,
  TimeLineDto,
  TimeLineType,
} from '@api-clients/dossier';
import { DefaultService as BimApi } from '@api-clients/bim';
import { lastValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import { FilterModel } from './FilterModel';
import { FormControl } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@services/user.service';
import { BlobReader, BlobWriter, ZipWriter } from '@zip.js/zip.js';

@Component({
  selector: 'app-dossier-detail',
  templateUrl: './dossier-detail.component.html',
  styleUrl: './dossier-detail.component.scss',
})
export class DossierDetailComponent {
  ds: TimelineDataSource;
  selectedTimelineItem: TimeLineDto | undefined;
  //TODO : We should not have a selected item for every type of timeline
  previouslySelectedNote: Note | undefined;
  previouslySelectedConstructionReport: ConstructionReport | undefined;
  previouslySelectedLocation: string | undefined;
  previouslySelectedBimLink: BimLink | undefined;
  previouslySelectedName: string | undefined;
  buildingName: string = '';
  menuExpanded: boolean = true;
  selectedFile: DossierImage | DossierFile | DossierDocument | undefined;
  selectedFileUrl: string | undefined;
  breadcrumbTree = [
    { translate: 'building', link: '/buildings' },
    { translate: 'dossier-page', link: this.router.url },
  ];

  locations: Location[] = [];
  protected readonly TimeLineType = TimeLineType;

  public timeLineTypes = Object.values(TimeLineType);
  public filterModel: FilterModel = {};

  @Input('control') dateTime: FormControl = new FormControl();
  @ViewChild('noteInput') private noteInput!: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dossierService: DossierService,
    private readonly usersInfoService: UsersInfoService,
    private readonly buildingOverviewService: BuildingOverviewService,
    private readonly buildingMenuService: BuildingMenuService,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private readonly userService: UserService,
    private readonly bimApi: BimApi
  ) {
    this.buildingMenuService.buildingMenuStatus$.pipe(takeUntilDestroyed()).subscribe((status) => {
      this.menuExpanded = status.expand;
    });
    if (!this.route.snapshot.params['id']) throw new Error('No dossier id');
    this.ds = new TimelineDataSource(
      this.dossierService,
      this.usersInfoService,
      this.route.snapshot.params['id'],
      this.filterModel
    );
    this.buildingOverviewService
      .getBuildingById(this.route.snapshot.params['id'])
      .pipe(takeUntilDestroyed())
      .subscribe((next) => {
        this.buildingName = next?.buildingMetadata?.address || '';
      });
    this.dossierService.getLocations().then((locations) => {
      this.locations = locations;
    });
  }

  postNoteEvent(): void {
    const noteInputValue = this.noteInput.nativeElement.value.trim();
    if (!noteInputValue || noteInputValue === '') return;
    this.ds.publishNoteEvent(noteInputValue).catch((e) => console.error(e));
    this.noteInput.nativeElement.value = '';
  }

  async onClickTimelineEvent(item: TimeLineDto): Promise<void> {
    this.selectedTimelineItem = item;
    this.selectedFile = undefined;

    let location: Location | undefined = undefined;

    const image_id: string = item.attachments?.images.length ? item.attachments?.images[0].id : '';
    if (image_id?.length) {
      await this.openImage(item, image_id);
    }

    switch (this.selectedTimelineItem.item_type) {
      case TimeLineType.Note:
        this.previouslySelectedNote = await this.dossierService.getNote(this.selectedTimelineItem);
        location = this.locations.find(
          (location) => location.id === this.previouslySelectedNote?.location_id
        );
        break;
      case TimeLineType.ConstructionReport:
        this.previouslySelectedConstructionReport = await this.dossierService.getConstructionReport(
          this.selectedTimelineItem
        );
        location = this.locations.find(
          (location) => location.id === this.previouslySelectedConstructionReport?.location_id
        );
        break;
      case TimeLineType.BimLink:
        this.previouslySelectedBimLink = await this.dossierService.getBimLinkForDossier(
          this.selectedTimelineItem
        );
        break;
      default:
        break;
    }

    const users = await lastValueFrom(
      this.usersInfoService.get([this.selectedTimelineItem.user_id])
    );
    this.previouslySelectedName = users[this.selectedTimelineItem.user_id];

    if (location != undefined && this.translateService.currentLang === 'en') {
      this.previouslySelectedLocation = location?.name_en;
    } else if (location != undefined) {
      this.previouslySelectedLocation = location?.name_nl;
    }
  }

  public refreshTimeLine(): void {
    this.ds = new TimelineDataSource(
      this.dossierService,
      this.usersInfoService,
      this.route.snapshot.params['id'],
      this.filterModel
    );
  }

  protected readonly environment = environment;

  public setTimeLineType(timeLineType?: TimeLineType): void {
    if (timeLineType?.toString() === 'undefined') {
      this.filterModel.timeLineType = undefined;
    } else {
      this.filterModel.timeLineType = timeLineType;
    }
    this.refreshTimeLine();
  }

  public setFromTo(value: Date): void {
    if (value !== null) {
      const until: Date = new Date(value[1].setHours(23, 59, 59, 999));
      this.filterModel.from = this.toDateTime(value[0]);
      this.filterModel.until = this.toDateTime(until);
      this.refreshTimeLine();
    }
  }

  public clearFromTo(): void {
    this.dateTime.setValue(null);
    this.filterModel.from = undefined;
    this.filterModel.until = undefined;
    this.refreshTimeLine();
  }

  private toDateTime(date: Date): string {
    const isoDate: string = date.toISOString();
    return isoDate.slice(0, 10) + ' ' + isoDate.slice(11, 19);
  }

  async openImage(item: TimeLineDto, image_id: string): Promise<void> {
    this.selectedTimelineItem = item;
    this.selectedFileUrl = await this.dossierService.getImagePresignedUrl(
      item.dossier_id,
      image_id
    );
    this.selectedFile = await this.dossierService.getImage(item.dossier_id, image_id);
  }

  async uploadIfc(event: Event): Promise<void> {
    if (!event) return;
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget.files?.[0];
    if (!file) throw new Error('No file selected');
    (document.getElementById('uploadButton')! as HTMLInputElement).value = '';

    let progress = 50;

    const reader = new BlobReader(file);
    const zipFileWriter = new BlobWriter();
    const zipWriter = new ZipWriter(zipFileWriter);
    await zipWriter.add(file.name, reader);
    const zipFileBlob = await zipWriter.close();

    const infoToast = this.toastr.info('Uploading IFC...', undefined, {
      extendedTimeOut: 100000000,
      timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
      enableHtml: true,
      tapToDismiss: false,
      progressBar: true,
      progressAnimation: 'increasing',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>infoToast).toastRef.componentInstance.updateProgress = (): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (<any>infoToast).toastRef.componentInstance.width = progress;
    };

    this.bimApi.bimIfcPost(this.userService.organizationId, zipFileBlob, 'events', true).subscribe({
      next: (event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            progress = (event.loaded / (event.total || 0)) * 100;
            break;
          case HttpEventType.Response:
            this.toastr.remove(infoToast.toastId);
            this.toastr.success('IFC file uploaded successfully', undefined, {
              positionClass: 'toast-bottom-right',
            });
            this.ds.publishIfcEvent('', event.body!.id);
            break;
        }
      },
      error: (error) => {
        console.error(error);
        this.toastr.remove(infoToast.toastId);
        this.toastr.error('Failed to upload IFC file', undefined, {
          positionClass: 'toast-bottom-right',
        });
      },
    });
  }
}
