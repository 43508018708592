import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Room } from '@shared/components/floor-plan/room';
import { Level } from '@shared/components/floor-plan/level';
import { Window } from '@shared/components/floor-plan/window';
import { Door } from '@shared/components/floor-plan/door';
import { FloorPlan } from '../../../views/model-viewer/utils/floorplan';
import { ModelLoaderService } from '@shared/services/model-loader.service';
import { DossierService } from '@services/dossier.service';
import { Wall } from '@shared/components/floor-plan/wall';
import { Dimensions } from '@shared/components/floor-plan/dimensions';
import { ShoppingCartService } from '../../../views/model-viewer/shopping-cart.service';
import { isBimRoom } from '../../../views/model-viewer/utils/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as svgPanZoom from 'svg-pan-zoom';
import { CategoryChange, Change } from '../../../views/model-viewer/changes-summary/change';

@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrl: './floor-plan.component.scss',
})
export class FloorPlanComponent implements OnInit {
  @ViewChild('svg', { static: false }) svg!: ElementRef<SVGSVGElement>;

  public levels: Level[] = [];
  public currentLevel = this.levels[0];
  protected floorPlan!: FloorPlan;
  protected viewBox: string = '0 0 0 0';
  public bimId: string | undefined;
  public scheme?: SvgPanZoom.Instance;

  @Input()
  public building_id: string | null = '';

  private readonly bucketFileName: string | null = null;

  @Output()
  public bimIdSelected: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public unselected = new EventEmitter();

  protected selectedElement: Window | Wall | Room | Door | undefined;
  protected clickDisabled = false;

  private cachedChanges: { room: Room; change: Change | CategoryChange }[] = [];

  constructor(
    private readonly modelLoader: ModelLoaderService,
    private readonly dossierService: DossierService,
    private readonly shoppingCartService: ShoppingCartService
  ) {
    this.shoppingCartService.content.pipe(takeUntilDestroyed()).subscribe((next) => {
      for (const changedElement of next) {
        for (const level of this.levels) {
          for (const change of changedElement.changes) {
            if (change instanceof CategoryChange) {
              const room = level.rooms.find((r) => r.bimId === changedElement.element.id);
              if (room && isBimRoom(changedElement.element.element)) {
                this.cachedChanges.push({ room: room, change });
                room.category = changedElement.element.element.category;
              }
            }
          }
        }
      }
    });
    this.shoppingCartService.removed_change.pipe(takeUntilDestroyed()).subscribe((change) => {
      const removed = this.cachedChanges.find((r) => r.change === change);
      if (!removed || !(change instanceof CategoryChange)) return;
      removed.room.category = (removed.change.oldValue ?? '').toString();
      this.cachedChanges.slice(this.cachedChanges.indexOf(removed), 1);
    });
  }

  async ngOnInit(): Promise<void> {
    await this.loadModel();
    setTimeout(() => {
      this.scheme = svgPanZoom(this.svg.nativeElement, {
        onUpdatedCTM: () => {
          this.clickDisabled = true;
        },
      });
    }, 1000);
  }

  async loadModel(): Promise<void> {
    let buildingModel;
    if (this.bucketFileName) {
      buildingModel = (
        await this.modelLoader.loadModel(
          `https://storage.googleapis.com/tt-3d-models/${this.bucketFileName}`
        )
      )?.scene.children[0];
    } else if (this.building_id) {
      // Todo: Dossier should not be called separately here. Centralize Dossier calls in a service?
      const dossier = await this.dossierService.getDossier(this.building_id);
      const bimLinks = await this.dossierService.getBimLinksForDossier(dossier.id);
      this.bimId = bimLinks.at(0)?.linked_bim_id;
      if (!this.bimId) throw new Error('No bimId found for dossier.');
      buildingModel = await this.modelLoader.loadCompositeModelWithMetadata(this.bimId);
    } else throw new Error('No model to load. Both bimId and bucketFileName are undefined.');

    if (!buildingModel) throw new Error('Failed to load model.');

    this.floorPlan = await FloorPlan.fromBuildingModel(buildingModel);

    this.levels = this.floorPlan.levels.map(
      (l) =>
        new Level(
          l.description,
          l.rooms.map((r) => new Room(r.polygon, r.roomCategory, r.bimId)),
          l.doors.map((d) => new Door(d.polygon, 0, d.bimId)),
          l.windows.map((w) => new Window(w.polygon, w.bimId)),
          l.walls.map((w) => new Wall(w.polygon, w.bimId)),
          new Dimensions(l.rooms.map((r) => new Room(r.polygon, r.roomCategory, r.bimId)))
        )
    );

    this.currentLevel = this.levels[0];

    const height =
      this.floorPlan.boundingBox.y2 -
      this.floorPlan.boundingBox.y1 +
      this.currentLevel.dimensions.widths.length * 200 +
      100;
    const width =
      this.floorPlan.boundingBox.x2 -
      this.floorPlan.boundingBox.x1 +
      this.currentLevel.dimensions.heights.length * 200 +
      100;
    this.viewBox = `${this.floorPlan.boundingBox.x1} ${this.floorPlan.boundingBox.y1} ${width} ${height}`;
  }

  protected unselect(): void {
    this.selectedElement = undefined;
    this.unselected.emit();
  }

  protected selectRoom(room: Room): void {
    if (this.clickDisabled) return;
    this.selectedElement = room;
    this.bimIdSelected.emit(room.bimId);
  }

  protected selectWindow(window: Window): void {
    if (this.clickDisabled) return;
    this.selectedElement = window;
    this.bimIdSelected.emit(window.bimId);
  }

  protected selectWall(wall: Wall): void {
    if (this.clickDisabled) return;
    this.selectedElement = wall;
    this.bimIdSelected.emit(wall.bimId);
  }

  protected selectDoor(door: Door): void {
    if (this.clickDisabled) return;
    this.selectedElement = door;
    this.bimIdSelected.emit(door.bimId);
  }

  public resetViewPort(): void {
    this.scheme?.resetZoom();
    this.scheme?.resetPan();
  }

  public zoomIn(): void {
    this.scheme?.zoomIn();
  }

  public zoomOut(): void {
    this.scheme?.zoomOut();
  }
}
