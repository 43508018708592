import { Component } from '@angular/core';
import { ItemStatusTypeDto, QuestionType } from '@api-clients/workspace';
import { WorkspaceDetailService } from '../../services/workspace-detail.service';

@Component({
  selector: 'app-workspace-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss'],
})
export class WorkspaceToolboxComponent {
  edit = false;

  constructor(private workspaceDetailService: WorkspaceDetailService) {
    this.workspaceDetailService.edit.subscribe((edit) => {
      this.edit = edit;
    });
  }

  functionClick(): void {}

  async insertSection(folder: boolean): Promise<void> {
    await this.workspaceDetailService.insertSection(folder);
  }

  insertCheckList(): void {
    this.workspaceDetailService.activeSection?.insertQuestion({
      label: '',
      questionType: QuestionType.MultipleChoice,
      isRequired: true,
      status: ItemStatusTypeDto.Unknown,
      properties: {},
      value: [],
    });
  }

  insertRadioList(): void {
    this.workspaceDetailService.activeSection?.insertQuestion({
      label: '',
      questionType: QuestionType.MultipleChoice,
      isRequired: true,
      status: ItemStatusTypeDto.Unknown,
      properties: { radio: true },
      value: [],
    });
  }

  insertOpenQuestion(): void {
    this.workspaceDetailService.activeSection?.insertQuestion({
      label: '',
      questionType: QuestionType.OpenQuestion,
      isRequired: true,
      status: ItemStatusTypeDto.Unknown,
      properties: {},
      value: '',
    });
  }

  insertFileUpload(): void {
    this.workspaceDetailService.activeSection?.insertFileRequest({
      isRequired: true,
      status: ItemStatusTypeDto.Unknown,
      contentItemIds: [],
      allowedFileTypes: [],
      description: '',
      name: '',
    });
  }
}
