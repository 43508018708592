import { Box3 } from 'three';
import { ObjectCategory } from '@api-clients/bim/model/objectCategory';
import { RoomCategory } from '@api-clients/bim';

export class BuildingObject {
  bimId: string;
  batchId: number;
  indices: [number, [number, number]] | undefined; // [meshIndex, [start, end]]
  rooms: Room[] = [];
  levels: Level[] = [];
  category?: Category;
  visible: boolean = true;
  colorCache?: Uint8Array;

  constructor(
    bim_id: string,
    batchId: number,
    indices:
      | [meshId: number, [vertexBufferStartIndex: number, vertexBufferEndIndex: number]]
      | undefined,
    rooms: Room[],
    levels: Level[],
    category?: Category
  ) {
    this.bimId = bim_id;
    this.batchId = batchId;
    this.indices = indices;
    this.rooms = rooms;
    this.levels = levels;
    this.category = category;
  }

  categoryName(): string {
    return this.category?.type ?? '';
  }

  updateVisibility(): void {
    const categoryVisible = this.category?.visible !== undefined ? this.category?.visible : true;
    const levelVisible = this.levels.some((level) => level.visible) || this.levels.length === 0;
    this.visible = categoryVisible && levelVisible;
  }
}

export class Level {
  bimId: string;
  level: number; // Level number (0 = ground floor)
  name: string; // Level name
  objects: BuildingObject[]; // BimObjects on this level
  rooms: Room[]; // Rooms on this level
  visible = true;
  boundingBox?: Box3;

  constructor(
    bimId: string,
    name: string,
    level: number,
    rooms: Room[],
    objects: BuildingObject[]
  ) {
    this.bimId = bimId;
    this.name = name;
    this.level = level;
    this.rooms = rooms;
    this.objects = objects;
  }

  setVisibility(visibility: boolean): void {
    this.visible = visibility;
    for (const element of this.objects) {
      element.updateVisibility();
    }
  }
}

export class Category {
  type: ObjectCategory;
  objects: BuildingObject[];
  visible = true;

  constructor(type: ObjectCategory, elements: BuildingObject[]) {
    this.type = type;
    this.objects = elements;
  }
}

export class Room {
  bimId!: string;
  category!: RoomCategory;
  objects: BuildingObject[] = [];
  levels: Level[] = [];
  batchId: number;
  indices: [meshIndex: number, [start: number, end: number]] | undefined;

  constructor(
    bimId: string,
    category: RoomCategory,
    objects: BuildingObject[],
    levels: Level[],
    batchId: number,
    indices: [meshIndex: number, [start: number, end: number]] | undefined
  ) {
    this.bimId = bimId;
    this.category = category;
    this.objects = objects;
    this.levels = levels;
    this.batchId = batchId;
    this.indices = indices;
  }
}
