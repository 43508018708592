export type bbox = { x1: number; y1: number; x2: number; y2: number };

/**
 * Get the bounding box of a polygon
 * @param polygons
 */
export function getBBox(polygons: { x: number; y: number }[]): bbox {
  const result = {
    x1: polygons[0].x,
    y1: polygons[0].y,
    x2: polygons[0].x,
    y2: polygons[0].y,
  };

  // calculate the bounding box
  for (const polygon of polygons) {
    if (result.x1 > polygon.x) {
      result.x1 = polygon.x;
    }
    if (result.y1 > polygon.y) {
      result.y1 = polygon.y;
    }
    if (result.x2 < polygon.x) {
      result.x2 = polygon.x;
    }
    if (result.y2 < polygon.y) {
      result.y2 = polygon.y;
    }
  }

  return result;
}

/**
 * Set the polygons to a string that can be read by svg
 * @param polygons
 */
export function toSvgPoints(polygons: { x: number; y: number }[]): string {
  let result = '';
  for (const polygon in polygons) {
    result += polygons[polygon].x + ',' + polygons[polygon].y + ' ';
  }
  return result;
}
