import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import { InspectionTemplateQuestion } from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-open-question',
  templateUrl: './open-question.component.html',
  styleUrl: './open-question.component.scss',
})
export class OpenQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => !this.first,
        action: () => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.last,
        action: () => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.delete.emit(),
      },
      {
        name: 'show-units',
        checked: () =>
          this.question.property.unit !== undefined && this.question.property.unit !== null,
        action: () => this.toggleUnits(),
      },
    ];
  }

  toggleUnits(): void {
    if (this.question.property.unit === undefined || this.question.property.unit === null) {
      this.question.property.unit = '';
    } else {
      this.question.property.unit = undefined;
    }
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }
}
