<div class="changes-summary">
  <div class="changes-summary-header">
    <h1 class="h6">{{ 'changes' | translate }}</h1>
  </div>
  <div class="changes-summary-content">
    <ng-container *ngFor="let changedElement of changes">
      <button
        class="changes-summary-content-item"
        (click)="activateElement(changedElement)"
        [class.delete]="changedElement.delete"
        *ngFor="let change of changedElement.changes">
        <button
          (click)="removeChange(changedElement, change, $event)"
          class="material-symbols-rounded close">
          close
        </button>

        <h3 class="h6">
          {{ getTypeName(changedElement.element) | lowercase | translate }}
        </h3>
        <p *ngIf="isPropertyChange(change) && change.newValue?.constructor?.name === 'Array'">{{ 'products-changed' | translate }}</p>
        <p *ngIf="isPropertyChange(change) && change.newValue?.constructor?.name !== 'Array'">
          {{ $any(change).propertyName | lowercase | translate }}
          {{
            change.oldValue !== undefined
              ? ('from' | translate) + ' ' + (change.oldValue.toString() | translate)
              : ''
          }}
          {{ 'changed_to' | translate }} {{ change.newValue?.toString() ?? '' | translate }}
        </p>
        <p *ngIf="isCategoryChange(change)">
          {{ 'category' | lowercase | translate }}
          {{
            change.oldValue !== undefined
              ? ('from' | translate) + ' ' + (change.oldValue.toString() | translate)
              : ''
          }}
          {{ 'changed_to' | translate }} {{ change.newValue?.toString() ?? '' | translate }}
        </p>
      </button>
    </ng-container>
  </div>
</div>
