import { Component, OnInit, ViewChild } from '@angular/core';
import { FloorPlanComponent } from '@shared/components/floor-plan/floor-plan.component';
import { BimElementDto, DefaultService as BimApi } from '@api-clients/bim';
import { ContextPopupService } from '../model-viewer/context-popup/context-popup.service';
import { ModelViewerEditingService } from '../model-viewer/model-viewer-editing-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ShoppingCartService } from '../model-viewer/shopping-cart.service';
import { filter, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryChange } from '../model-viewer/changes-summary/change';
import { isBimObject } from '../model-viewer/utils/types';

@Component({
  selector: 'app-floor-plan-viewer',
  templateUrl: './floor-plan-viewer.component.html',
  styleUrl: './floor-plan-viewer.component.scss',
})
export class FloorPlanViewerComponent implements OnInit {
  @ViewChild(FloorPlanComponent, { static: true }) floorPlan!: FloorPlanComponent;

  protected building_id: string | null;
  protected context = this.contextPopupService.context;
  protected shoppingCartVisible: boolean = false;
  protected shoppingCartCount: number = 0;
  protected isSaving: boolean = false;
  protected breadcrumbTree: { name: string; link: string }[] = [];
  private hasObjectCategoryChanges: boolean = false;

  constructor(
    private readonly bimApi: BimApi,
    private readonly contextPopupService: ContextPopupService,
    private readonly modelViewerEditingService: ModelViewerEditingService,
    private readonly route: ActivatedRoute,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService
  ) {
    this.building_id = route.snapshot.paramMap.get('building_id');

    contextPopupService.contextChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.context = value;
    });

    this.shoppingCartService.content.pipe(takeUntilDestroyed()).subscribe((next) => {
      this.shoppingCartCount = next.flatMap((c) => c.changes).length;
      this.hasObjectCategoryChanges = false;
      for (const ce of next.filter((c) => isBimObject(c.element.element))) {
        this.hasObjectCategoryChanges =
          ce.changes.findIndex((c) => c instanceof CategoryChange) > -1;
      }

      if (next.length === 0 && this.shoppingCartVisible) {
        void this.router.navigate(['details'], { relativeTo: this.route });
      }
    });

    this.modelViewerEditingService.addedToShoppingCart
      .pipe(takeUntilDestroyed())
      .subscribe((next) => {
        if (next > 0) {
          this.contextPopupService.cartAnimate();
        }

        this.contextPopupService.hide();
        this.unselect();
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).url)
      )
      .subscribe((next) => {
        this.shoppingCartVisible = next.substring(next.lastIndexOf('/') + 1) === 'changes';
        this.unselect();
        this.updateBreadCrumb();
      });
  }

  async ngOnInit(): Promise<void> {
    this.updateBreadCrumb();
  }

  protected bimIdSelected(id: string): void {
    const bimId = this.floorPlan.bimId;
    if (id) {
      if (
        !bimId ||
        id.length < 10 // Check for meshes with no matching bimId
      )
        return;

      this.bimApi
        .bimBimIdElementsElementIdGet(bimId, id)
        .subscribe(this.elementPropertiesPopup.bind(this));
    } else {
      this.contextPopupService.hide();
    }
  }

  protected unselect(): void {
    this.contextPopupService.hide();
  }

  updateBreadCrumb(): void {
    this.breadcrumbTree = [];
    this.breadcrumbTree.push({
      name: this.translateService.instant('floor-plan'),
      link: '/buildings/' + this.route.snapshot.paramMap.get('building_id') + '/floor-plan/details',
    });
    if (this.shoppingCartVisible) {
      this.breadcrumbTree.push({
        name: this.translateService.instant('changes'),
        link:
          '/buildings/' + this.route.snapshot.paramMap.get('building_id') + '/floor-plan/changes',
      });
    }
  }

  private elementPropertiesPopup(element: BimElementDto): void {
    if (!this.floorPlan.bimId) return;
    const propertyDefinition = this.modelViewerEditingService.popupElement(
      this.floorPlan.bimId,
      element
    );
    if (!propertyDefinition) return;

    this.contextPopupService.show();
  }

  async showShoppingCart(): Promise<void> {
    await this.router.navigate(['changes'], { relativeTo: this.route });
  }

  async saveShoppingCart(): Promise<void> {
    if (this.isSaving) return;

    this.isSaving = true;

    await this.modelViewerEditingService.saveShoppingCart();

    if (this.hasObjectCategoryChanges) {
      await this.floorPlan.loadModel();
    }

    this.toastr.success(this.translateService.instant('changes_saved'));

    this.isSaving = false;

    await this.router.navigate(['details'], { relativeTo: this.route });
  }

  downloadSvg(): void {
    this.floorPlan.resetViewPort();
    window.setTimeout(() => {
      const svg_content = this.floorPlan.svg.nativeElement.outerHTML;
      const blob = new Blob([svg_content], {
        type: 'image/svg+xml',
      });

      const downloadelem = document.createElement('a');
      const url = URL.createObjectURL(blob);
      downloadelem.download = `floorplan - ${this.floorPlan.currentLevel.description}.svg`;
      downloadelem.href = url;
      downloadelem.click();
      downloadelem.remove();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}
