import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Question, RequestContentItem, Section, Workspace } from '../../models';
import { WorkspaceDetailService } from '../../services/workspace-detail.service';
import { distinctUntilChanged, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import leven from 'leven';
import { ContextService } from '@services/context.service';
import { WorkspaceService } from '../../services/workspace.service';
import { BuildingMenuService } from '@shared/services/building-menu.service';

@Component({
  selector: 'app-workspace-detail',
  templateUrl: './workspace-detail.component.html',
  styleUrls: ['./workspace-detail.component.scss'],
})
export class WorkspaceDetailComponent {
  rooms: { name: string; id: string }[] = [];
  buildingId?: string = '';
  workspaceId?: string = '';
  edit = false;
  selectedRoom$ = new EventEmitter<string>();
  filteredSections: Section[] = [];
  searchValue: string = '';
  menuExpanded: boolean = true;

  breadcrumbTree = [
    {
      name: 'Gebouwen',
      link: '/buildings',
    },
    {
      name: 'Gebouwstraat 1',
      link: '/buildings/6fdac3ae-2e4f-46f7-87cc-0273a97fd494',
    },
    {
      name: 'Inspecties',
      link: '/buildings/6fdac3ae-2e4f-46f7-87cc-0273a97fd494/workspaces',
    },
    {
      name: 'Workspace  - De inspectie',
      link: '/buildings/6fdac3ae-2e4f-46f7-87cc-0273a97fd494/workspaces/test',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workspaceDetailService: WorkspaceDetailService,
    private workspaceService: WorkspaceService,
    private contextService: ContextService,
    private buildingMenuService: BuildingMenuService
  ) {
    this.buildingId = route.snapshot.paramMap.get('id') ?? undefined;
    this.workspaceId = route.snapshot.paramMap.get('workspaceId') ?? undefined;
    const sectionId = route.snapshot.queryParams['section'] ?? undefined;
    if (this.workspaceId)
      this.workspaceDetailService
        .loadWorkspace(this.workspaceId, sectionId)
        .then(() => this.updateBreadcrumb());

    this.route.queryParams
      .pipe(
        takeUntilDestroyed(),
        map((params) => params['section']),
        distinctUntilChanged()
      )
      .subscribe((sectionId?: string) => {
        this.openSection(sectionId);
      });

    this.workspaceDetailService.edit.pipe(takeUntilDestroyed()).subscribe((edit) => {
      this.edit = edit;
    });

    this.buildingMenuService.buildingMenuStatus$.pipe(takeUntilDestroyed()).subscribe((status) => {
      this.menuExpanded = status.expand;
    });
  }

  get workspacePath(): string {
    return `/buildings/${this.buildingId}/workspaces/${this.workspaceId}`;
  }

  get workspace(): Workspace | undefined {
    return this.workspaceDetailService.currentWorkspace;
  }

  get currentSection(): Section | undefined {
    return this.workspaceDetailService.currentSection;
  }

  set currentSection(section: Section | undefined) {
    this.workspaceDetailService.currentSection = section;
  }

  get activeSection(): Section | undefined {
    return this.workspaceDetailService.activeSection;
  }

  set activeSection(section: Section | undefined) {
    this.workspaceDetailService.activeSection = section;
  }

  get progressPercentage(): number {
    let length = 0;
    let completed = 0;

    this.workspace?.allItems.forEach((item) => {
      if (item.isQuestion() || item.isFileRequest()) {
        length++;
        if ((item as Question | RequestContentItem).isCompleted) {
          completed++;
        }
      }
    });

    return Math.round((completed / length) * 100);
  }

  async updateWorkspace(): Promise<void> {
    await this.workspaceDetailService.updateWorkspace();
    this.updateBreadcrumb();
  }

  itemDropped(event: CdkDragDrop<Section>): void {
    if (event.previousIndex === event.currentIndex) return;
    this.currentSection?.updateChildPosition(event.previousIndex, event.currentIndex);
  }

  activateSection(section: Section): void {
    this.activeSection = section;
  }

  /// Opens the section with the given id. If no id is given, the root section is opened.
  openSection(sectionId?: string): void {
    if (!sectionId) {
      this.openRootSection();
    } else {
      this.currentSection = this.workspace?.getSection(sectionId);
      this.changeRoute(this.workspacePath, { section: sectionId });
    }
    this.updateBreadcrumb();
  }

  async deleteSection(sectionId: string): Promise<void> {
    if (!this.currentSection || !sectionId) return;
    // Update view so that the section is removed from the view before the request is sent
    this.currentSection.items = this.currentSection.items.filter(
      (section) => section.id !== sectionId
    );
    await this.workspaceService.deleteSection(this.currentSection.workspaceId, sectionId);
  }

  openRootSection(): void {
    this.currentSection = this.workspace?.rootSection;
    this.changeRoute(this.workspacePath);
  }

  changeRoute(link: string, queryParams?: Record<string, string>): void {
    void this.router.navigate([link], {
      queryParams,
      queryParamsHandling: '',
    });
  }

  updateBreadcrumb(): void {
    const workspacePath = this.workspaceDetailService.currentPath().map(({ name, id }) => {
      return {
        name: name.length ? name : 'Folder',
        link: this.workspacePath,
        queryParams: id.length ? { section: id } : undefined,
      };
    });
    this.breadcrumbTree = [
      {
        name: 'Gebouwen',
        link: '/buildings',
      },
      {
        name: 'Gebouwstraat 1',
        link: `/buildings/${this.buildingId}`,
      },
      {
        name: 'Inspecties',
        link: `/buildings/${this.buildingId}/workspaces`,
      },
      {
        name: this.workspace?.name ?? 'Nameless workspace',
        link: this.workspacePath,
      },
      ...workspacePath,
    ];
  }

  handleSearchChange(value: string): void {
    this.searchValue = value;
    this.filteredSections = [];

    this.currentSection?.sections.forEach((section) => {
      const diff = leven(value, section.name);

      if (section.name.toLowerCase().includes(value.toLowerCase()) || diff <= 3) {
        this.filteredSections.push(section);
      }
    });
  }

  hideContext(): void {
    this.contextService.hide();
  }
}
