<div class="workspace-overview">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'form-overview-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <button class="button-secondary button--has-icon" (click)="createNewPopup()">
        <span class="material-symbols-rounded">add</span>
        {{ 'create-new-form' | translate }}
      </button>
      <input
        class="top-search"
        type="search"
        [(ngModel)]="searchText"
        (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <div class="list-container">
    <app-workspace-list
      [workspaces]="workspaces"
      [listTitle]="'recently-added' | translate"
      [linkTitle]="'show-all' | translate"
      (deleteAction)="deleteWorkspace($event)"
      (linkAction)="scrollDown()"></app-workspace-list>
    <app-workspace-list
      [workspaces]="workspaces"
      [listTitle]="'for-you' | translate"
      [linkTitle]="'show-all' | translate"
      (linkAction)="scrollDown()"></app-workspace-list>
  </div>

  <h2 class="form-subtitle">{{ 'all-forms' | translate }}</h2>

  <app-table-filter></app-table-filter>
  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key.length" class="sortable">
            {{ column.label | translate }}
            <span
              [class.arrow-active]="sortBy === column.key"
              [class.arrow-down]="sortOrder === 1"
              class="arrow"></span>
          </th>
          <th *ngIf="!column.key.length">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let workspace of filteredWorkspaces"
        [contextMenu]="menu"
        (mousedown)="setActive(workspace.id)">
        <td
          [attr.data-title]="'id' | translate"
          (click)="navigateToWorkspace(workspace.id)"
          class="workspace-link">
          {{ workspace.id }}
        </td>
        <td
          [attr.data-title]="'name' | translate"
          (click)="navigateToWorkspace(workspace.id)"
          class="workspace-link">
          {{ workspace.name }}
        </td>
        <td
          [attr.data-title]="'coupled-building' | translate"
          class="coupled-building"
          (click)="goToBuilding()">
          Corvinus University
          <span class="material-symbols-rounded">arrow_outward</span>
        </td>
        <td [attr.data-title]="'status' | translate">
          <app-workspace-status-bubble [status]="'completed'"></app-workspace-status-bubble>
        </td>
        <td [attr.data-title]="'actions' | translate" class="actions">
          <app-three-dot [menu]="menu"></app-three-dot>
        </td>
      </tr>
    </tbody>
  </table>

  <app-table-pagination
    [page]="1"
    [pageSize]="10"
    (pageChange)="onPageChange($event)"
    [total]="workspaces.length"></app-table-pagination>

  <app-confirmation-dialog
    [title]="'workspace-delete-title' | translate"
    [description]="'workspace-delete-description' | translate"
    [icon]="'bin'"
    [confirmText]="'workspace-delete-confirm' | translate"
    (confirmOutput)="deleteWorkspace(activeWorkspace)"></app-confirmation-dialog>

  <app-workspace-select-dialog
    (selectedOutput)="createNewWorkspace($event)"></app-workspace-select-dialog>
</div>
