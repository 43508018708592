<div class="section-item_drag_handle_container">
  <ng-content></ng-content>
</div>

<app-inspection-open-question
  *ngIf="item.property.property_type === InspectionTemplatePropertyType.Text || item.property.property_type === InspectionTemplatePropertyType.Number || item.property.property_type === InspectionTemplatePropertyType.Boolean"
  #child
  [question]="item"
  [edit]="edit"
  [first]="first"
  [last]="last"
  (move)="move.emit($event)"
  (delete)="deleteChild.emit(item)"></app-inspection-open-question>
<app-inspection-images-question
  *ngIf="item.property.property_type === InspectionTemplatePropertyType.ImageList"
  #child
  [question]="item"
  [edit]="edit"
  [first]="first"
  [last]="last"
  (move)="move.emit($event)"
  (delete)="deleteChild.emit(item)"></app-inspection-images-question>
<app-inspection-checklist
  *ngIf="item.property.property_type === InspectionTemplatePropertyType.CheckList || item.property.property_type === InspectionTemplatePropertyType.RadioList"
  #child
  [question]="item"
  [edit]="edit"
  [first]="first"
  [last]="last"
  (move)="move.emit($event)"
  (delete)="deleteChild.emit(item)"></app-inspection-checklist>
