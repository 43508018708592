<div class="workspace-detail">
  <app-workspace-progress
    [biggerVersion]="true"
    [menuExpanded]="menuExpanded"
    [progress]="progressPercentage"></app-workspace-progress>

  <div class="workspace-detail__container" (scroll)="hideContext()">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

    <div class="intro">
      <h1 *ngIf="!edit">{{ this.workspace?.name }}</h1>
      <p class="p-small" *ngIf="!edit">
        {{ this.workspace?.description }}
      </p>

      <input
        class="h1 title-input"
        type="text"
        autocomplete="off"
        [(ngModel)]="this.workspace!.name"
        (blur)="updateWorkspace()"
        placeholder="{{ 'workspace-title' | translate }}"
        *ngIf="edit"/>
      <textarea
        rows="3"
        autocomplete="off"
        class="description-input"
        *ngIf="edit"
        (blur)="updateWorkspace()"
        placeholder="{{ 'workspace-description' | translate }}"
        [(ngModel)]="this.workspace!.description"></textarea>

      <app-workspace-top-bar (searchChange)="handleSearchChange($event)"></app-workspace-top-bar>
    </div>
    <div
      *ngIf="currentSection"
      cdkDropList
      cdkDropListGroup
      (cdkDropListDropped)="itemDropped($event)"
      class="item-list">
      <app-workspace-section
        *ngFor="
          let section of searchValue.length > 0 ? filteredSections : currentSection.sections;
          let i = index
        "
        (click)="activateSection(section)"
        [section]="section"
        [sectionIndex]="i"
        [isLast]="i === currentSection.sections.length - 1"
        [prevIsFolder]="(currentSection.sections.at(i - 1)?.isFolder || false) && i !== 0"
        [nextIsFolder]="currentSection.sections.at(i + 1)?.isFolder || false"
        (openSection)="openSection(section.id)"
        (deleteSection)="deleteSection(section.id)"></app-workspace-section>
    </div>

    <app-workspace-toolbox></app-workspace-toolbox>
  </div>

  <app-page-slider [menuExpanded]="menuExpanded">
    <!--    <app-floor-plan [buildingId]="buildingId" [selectedRoom$]="selectedRoom$"></app-floor-plan>-->
  </app-page-slider>
</div>
