import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ContextPopupService } from '../../../views/model-viewer/context-popup/context-popup.service';
import { Category, Level } from '@shared/services/assets/building-elements';
import { ObjectCategory } from '@api-clients/bim';

export interface ItemVisibility {
  name: string;
  visibility: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-residence-sidebar',
  templateUrl: './residence-sidebar.component.html',
  styleUrls: ['./residence-sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'out',
        style({
          height: '*',
        })
      ),
      state(
        'in',
        style({
          height: '0',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class ResidenceSidebarComponent implements OnInit {
  context;
  subscription;
  isAuthenticated = false;

  @Input() categories?: Category[];
  @Input() levels?: Level[];
  @Output() toggleCategoryVisibility = new EventEmitter<ObjectCategory>();
  @Output() toggleLevelVisibility = new EventEmitter<number>();

  breadcrumbTree = [
    {
      name: 'Woning detail',
      link: '/speckle-viewer',
    },
  ];

  constructor(
    private oidcSecurityService: OidcSecurityService,
    public contextService: ContextPopupService
  ) {
    this.context = contextService.context;
    this.subscription = contextService.contextChange.subscribe((value) => {
      this.context = value;
    });
  }

  toggleCategory(category: ObjectCategory): void {
    this.toggleCategoryVisibility.emit(category);
  }

  toggleLevel(level: number): void {
    this.toggleLevelVisibility.emit(level);
  }

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$
      .pipe(untilDestroyed(this))
      .subscribe(({ isAuthenticated }) => (this.isAuthenticated = isAuthenticated));
  }
}
