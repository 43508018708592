import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InspectionTemplateQuestion } from '@api-clients/dossier';
import { ContextMenuItem } from '@services/context.service';

@Component({
  selector: 'app-inspection-images-question',
  templateUrl: './images-question.component.html',
  styleUrl: './images-question.component.scss',
})
export class ImagesQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => !this.first,
        action: () => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.last,
        action: () => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.delete.emit(),
      },
    ];
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }
}
