import { BuildingModel } from '@shared/services/assets/building-model';
import { Level as BuildingModelLevel } from '@shared/services/assets/building-elements';
import { ObjectCategory, RoomCategory } from '@api-clients/bim';
import { Mesh } from 'three';
import * as clipperLib from 'js-angusj-clipper/web'; // es6 / typescript

type BatchIdToFaceIndices = Map<number, number[]>[];
let clipper: clipperLib.ClipperLibWrapper;

const SCALE = 1e6;

export class BoundingBox {
  x1: number;
  x2: number;
  y1: number;
  y2: number;

  constructor(x1: number, x2: number, y1: number, y2: number) {
    this.x1 = x1;
    this.x2 = x2;
    this.y1 = y1;
    this.y2 = y2;
  }

  center(): [number, number] {
    return [(this.x1 + this.x2) / 2, (this.y1 + this.y2) / 2];
  }

  join(other: BoundingBox): BoundingBox {
    return new BoundingBox(
      Math.min(this.x1, other.x1),
      Math.max(this.x2, other.x2),
      Math.min(this.y1, other.y1),
      Math.max(this.y2, other.y2)
    );
  }

  static fromPoly(polygon: { x: number; y: number }[]): BoundingBox {
    let x1 = Number.POSITIVE_INFINITY;
    let x2 = Number.NEGATIVE_INFINITY;
    let y1 = Number.POSITIVE_INFINITY;
    let y2 = Number.NEGATIVE_INFINITY;
    for (const { x, y } of polygon) {
      x1 = Math.min(x1, x);
      x2 = Math.max(x2, x);
      y1 = Math.min(y1, y);
      y2 = Math.max(y2, y);
    }
    return new BoundingBox(x1, x2, y1, y2);
  }
}

export class FloorPlan {
  levels: Level[] = [];
  boundingBox!: BoundingBox;

  static async fromBuildingModel(model: BuildingModel): Promise<FloorPlan> {
    clipper = await clipperLib.loadNativeClipperLibInstanceAsync(
      clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback
    );
    const batchIdToFaceIndices = model.children.map((child) => {
      const batchIdToFaceIndices = new Map<number, number[]>();
      const faceIndexArray = (child as Mesh).geometry.index!.array;
      const vertexBatchIndexArray = (child as Mesh).geometry.attributes['_batch_ids']!.array;
      for (let i = 0; i < faceIndexArray.length; i += 3) {
        const vertexIndex = faceIndexArray[i];
        const batchId = vertexBatchIndexArray[vertexIndex];
        const facesForBatchId = batchIdToFaceIndices.get(batchId);
        if (facesForBatchId) {
          facesForBatchId.push(i / 3);
        } else {
          batchIdToFaceIndices.set(batchId, [i / 3]);
        }
      }
      return batchIdToFaceIndices;
    });
    const levels = model.levels.map((level) =>
      Level.fromBuildingModelLevel(level, model, batchIdToFaceIndices)
    );
    const boundingBox = levels
      .map((level) => level.boundingBox)
      .filter((box) => box)
      .reduce((acc, box) => acc!.join(box!)) as BoundingBox;

    return { levels, boundingBox };
  }
}

export class Level {
  description: string = '';
  rooms: { polygon: { x: number; y: number }[]; bimId: string; roomCategory: RoomCategory }[] = [];
  floors: { polygon: { x: number; y: number }[]; bimId: string }[] = [];
  walls: { polygon: { x: number; y: number }[]; bimId: string }[] = [];
  windows: { polygon: { x: number; y: number }[]; bimId: string }[] = [];
  doors: { polygon: { x: number; y: number }[]; bimId: string }[] = [];
  stairs: { polygon: { x: number; y: number }[]; bimId: string }[] = [];

  boundingBox?: BoundingBox;

  static fromBuildingModelLevel(
    level: BuildingModelLevel,
    model: BuildingModel,
    batchIdToFaceIndices: BatchIdToFaceIndices
  ): Level {
    const objectsWithGeometry = level.objects.filter((object) => object.indices);
    const floors = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Floor)
      .flatMap((object) => objectToPolygons(object, model, batchIdToFaceIndices))
      .flat();
    const windows = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Window)
      .flatMap((object) => objectToPolygons(object, model, batchIdToFaceIndices))
      .flat();
    const walls = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Wall)
      .flatMap((object) => objectToPolygons(object, model, batchIdToFaceIndices))
      .flat();
    const doors = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Door)
      .flatMap((object) => objectToPolygons(object, model, batchIdToFaceIndices))
      .flat();
    const stairs = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Stair)
      .map((object) => objectToPolygons(object, model, batchIdToFaceIndices))
      .flat();

    const rooms = level.rooms
      .filter((room) => room.indices)
      .map((room) =>
        objectToPolygons(room, model, batchIdToFaceIndices).map(({ polygon, bimId }) => {
          return { polygon, bimId, roomCategory: room.category };
        })
      )
      .flat();

    const elements = walls.concat(...windows, ...doors, ...stairs, ...floors);
    const boundingBox = elements.length
      ? elements
          .map((object) => BoundingBox.fromPoly(object.polygon))
          .reduce((acc, box) => acc.join(box))
      : undefined;

    return { description: level.name, floors, walls, windows, doors, stairs, rooms, boundingBox };
  }
}

function objectToPolygons(
  object: { batchId: number; bimId: string; indices: [number, [number, number]] | undefined },
  model: BuildingModel,
  batchIdToFaceIndices: BatchIdToFaceIndices
): { polygon: { x: number; y: number }[]; bimId: string }[] {
  const meshIndex = object.indices![0];
  const faceIndices = batchIdToFaceIndices[meshIndex].get(object.batchId);
  if (!faceIndices) {
    console.warn('No faces for batch id: ', object.batchId);
    return [];
  }
  const mesh = model.children[meshIndex] as Mesh;
  const positionBuffer = mesh.geometry.attributes['position'].array;
  const indexBuffer = mesh.geometry.index!.array;
  const triangles: { x: number; y: number }[][] = [];
  for (const faceIndex of faceIndices) {
    const v1 = indexBuffer[faceIndex * 3] * 3;
    const v2 = indexBuffer[faceIndex * 3 + 1] * 3;
    const v3 = indexBuffer[faceIndex * 3 + 2] * 3;
    const polygon = [
      { x: positionBuffer[v1] * SCALE, y: positionBuffer[v1 + 2] * SCALE }, // vertex 1 x, z
      { x: positionBuffer[v2] * SCALE, y: positionBuffer[v2 + 2] * SCALE }, // vertex 2 x, z
      { x: positionBuffer[v3] * SCALE, y: positionBuffer[v3 + 2] * SCALE }, // vertex 3 x, z
    ];
    if (frontFacing(polygon)) triangles.push(polygon);
  }
  if (triangles.length == 0) {
    return [];
  }
  const mergedPolygons = clipper.clipToPaths({
    clipType: clipperLib.ClipType.Union,
    subjectInputs: [{ data: triangles[0], closed: true }],
    clipInputs: [{ data: triangles.slice(1) }],
    subjectFillType: clipperLib.PolyFillType.NonZero,
  });
  for (const vertex of mergedPolygons.flat(2)) {
    vertex.x = (vertex.x / SCALE) * 1000;
    vertex.y = (vertex.y / SCALE) * 1000;
  }
  return mergedPolygons.map((polygon) => ({ polygon: polygon, bimId: object.bimId }));
}

function frontFacing(polygon: { x: number; y: number }[]): boolean {
  const a = polygon[0];
  const b = polygon[1];
  const c = polygon[2];
  return (b.x - a.x) * (c.y - a.y) - (c.x - a.x) * (b.y - a.y) > SCALE * 1e-10;
}
