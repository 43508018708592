<div class="building-info" *ngIf="selectedBuilding">
  <div class="header">
    <h2 class="h5">{{ 'buildingInfo' | translate }}</h2>
    <button (click)="closeSidebar()" class="material-symbols-rounded">
      {{ 'close' | translate }}
    </button>
  </div>
  <div class="specs">
    <div *ngIf="selectedBuilding.buildingMetadata as metadata">
      <h3 class="h5">{{ metadata.address }}</h3>
      <h4 class="addres">{{ metadata.postalCode }}</h4>
      <h4 class="addres">{{ metadata.city }}</h4>
    </div>
    <ul>
      <li *ngIf="selectedBuilding.external_id as id">
        <span class="property">{{ 'cadastralIds' | translate }}</span>
        <span class="value">{{ id }}</span>
      </li>
      <!--suppress TypeScriptValidateTypes -->
      <li *ngFor="let item of selectedBuilding.buildingMetadata | additionalProperties | keyvalue">
        <span class="property">{{ item.key | translate }}</span>
        <span class="value">{{ item.value }}</span>
      </li>
      <li *ngIf="selectedBuilding.buildingMetadata?.surface">
        <span class="property">{{ 'surface' | translate }}</span>
        <span class="value">{{ selectedBuilding.buildingMetadata?.surface }} m2</span>
      </li>
      <li *ngIf="selectedBuilding.buildingMetadata?.usages">
        <span class="property">{{ 'usages' | translate }}</span>
        <span class="value right" [innerHTML]="selectedBuilding.buildingMetadata?.usages?.join('<br/>')"></span>
      </li>
    </ul>
  </div>

  <div class="bottom-bar">
    <button (click)="openDossier()" class="button-primary" *ngIf="selectedBuilding.real_estate_id">
      {{ 'dossier' | translate }}
    </button>
    <button class="button" (click)="addBuilding()" *ngIf="!selectedBuilding.real_estate_id">
      <span class="material-symbols-rounded">add</span>
      {{ 'add_to_account' | translate }}
    </button>
  </div>
</div>
