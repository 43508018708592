import { getBBox, toSvgPoints } from '@shared/components/floor-plan/utils';

export class Window {
  public readonly polygons: { x: number; y: number }[];
  public readonly bbox: { x1: number; y1: number; x2: number; y2: number };
  public readonly points: string;
  public readonly rotate: number;
  public readonly bimId: string;
  public readonly width: number;
  public readonly height: number;

  constructor(polygons: { x: number; y: number }[], bimId: string) {
    this.polygons = polygons;
    this.bbox = getBBox(this.polygons);
    this.points = toSvgPoints(this.polygons);
    this.bimId = bimId;

    this.width = this.bbox.x2 - this.bbox.x1;
    this.height = this.bbox.y2 - this.bbox.y1;

    if (this.width < this.height) {
      this.rotate = -90;
    } else {
      this.rotate = 0;
    }
  }
}
