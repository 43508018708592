import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ContextMenuItem {
  name: string;
  icon?: string;
  visible?: boolean | (() => boolean);
  style?: string;
  action?: () => void;
  interpolationParams?: { [key: string]: string | boolean };
}

export interface Context {
  active: boolean;
  animate: boolean;
  list: ContextMenuItem[];
  action?: (item: ContextMenuItem) => void;
}

@Injectable({
  providedIn: 'root',
})
export class ContextPopupService {
  context: Context = { active: false, animate: false, list: [] };
  contextChange: Subject<Context> = new Subject<Context>();
  hidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  get(): Context {
    return this.context;
  }

  hide(): void {
    this.context.active = false;
    this.hidden.emit(true);
  }

  show(): void {
    this.context.active = true;
  }

  cartAnimate(): void {
    this.context.animate = true;

    setTimeout(() => {
      this.context.animate = false;
    }, 500);
  }
}
