import { Dossier, TimeLineType } from '@api-clients/dossier';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from 'rxjs';
import { DossierService } from '@services/dossier.service';
import { UsersInfoService } from '@api-clients/user';
import { FilterModel } from './FilterModel';
import { EnrichedTimeLineDto } from './EnrichedTimeLineDto';

export class TimelineDataSource extends DataSource<EnrichedTimeLineDto | undefined> {
  private pageSize = 8;
  private cachedData = Array.from<EnrichedTimeLineDto>({ length: 0 });
  private fetchedPages = new Set<number>();
  private readonly dataStream = new BehaviorSubject<(EnrichedTimeLineDto | undefined)[]>(
    this.cachedData
  );

  private readonly _subscription = new Subscription();
  private dossier: Dossier | undefined;

  constructor(
    private dossierService: DossierService,
    private usersInfoService: UsersInfoService,
    private buildingId: string,
    private filterModel: FilterModel
  ) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(EnrichedTimeLineDto | undefined)[]> {
    this.dossierService.getDossier(this.buildingId).then((dossier) => {
      this.dossier = dossier;

      this.dossierService
        .getDossierEvents(
          dossier.id,
          1,
          this.pageSize,
          this.filterModel.timeLineType,
          this.filterModel.from,
          this.filterModel.until
        )
        .then((result) => {
          this.cachedData = Array.from<EnrichedTimeLineDto>({ length: result.count });

          this._subscription.add(
            collectionViewer.viewChange.subscribe((range) => {
              const startPage = this.getPageForIndex(range.start);
              const endPage = this.getPageForIndex(range.end - 1);

              for (let i = startPage; i <= endPage; i++) {
                this.fetchPage(i).catch(() => {});
              }
            })
          );
          this.dataStream.next(this.cachedData);
        });
    });
    return this.dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  async publishNoteEvent(note: string): Promise<void> {
    if (!this.dossier) return;
    const newEvent = await this.dossierService.postNoteEvent(this.dossier.id, {
      rich_text: note,
      image_ids: [],
    });
    const users = await lastValueFrom(this.usersInfoService.get([newEvent.user_id]));
    const enrichedEvent: EnrichedTimeLineDto = {
      ...newEvent,
      description: note,
      userName: users[newEvent.user_id],
      item_id: newEvent.id,
      item_type: TimeLineType.Note,
    };

    this.cachedData = [enrichedEvent, ...this.cachedData];
    this.dataStream.next(this.cachedData);
    this.dossier.event_count++;
  }

  async publishIfcEvent(description: string, linked_bim_id: string): Promise<void> {
    if (!this.dossier) return;
    const newEvent = await this.dossierService.postIfcEvent(this.dossier.id, {
      description,
      linked_bim_id,
    });
    const users = await lastValueFrom(this.usersInfoService.get([newEvent.user_id]));
    const enrichedEvent: EnrichedTimeLineDto = {
      ...newEvent,
      timestamp_utc: Date(),
      userName: users[newEvent.user_id],
      item_id: newEvent.id,
      item_type: TimeLineType.BimLink,
    };

    this.cachedData = [enrichedEvent, ...this.cachedData];
    this.dataStream.next(this.cachedData);
    this.dossier.event_count++;
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private async fetchPage(page: number): Promise<void> {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);

    //get all events for the current page and enrich them with separately fetched user's names
    const timeLineResponse = await this.dossierService.getDossierEvents(
      this.dossier!.id,
      page + 1,
      this.pageSize,
      this.filterModel.timeLineType,
      this.filterModel.from,
      this.filterModel.until
    );
    const events = timeLineResponse.items;
    const ids = events.map((event) => event.user_id);
    const users = await lastValueFrom(this.usersInfoService.get(ids));
    const enrichedEvents = events.map((event) => ({ ...event, userName: users[event.user_id] }));

    //add event to the data cache and notify the data stream
    this.cachedData.splice(
      page * this.pageSize,
      events.length,
      ...Array.from({ length: enrichedEvents.length }).map((_, i) => enrichedEvents[i])
    );
    this.dataStream.next(this.cachedData);
  }
}
