import { Room } from '@shared/components/floor-plan/room';
import { LinePiece } from '@shared/components/floor-plan/line-piece';

export class Dimensions {
  public readonly heights: LinePiece[][] = [];
  public readonly widths: LinePiece[][] = [];

  private tooClose(a: LinePiece, b: LinePiece): boolean {
    return Math.abs(a.p1 - b.p1) < 50 && Math.abs(a.p2 - b.p2) < 50;
  }

  private handleTooClose(linePieces: LinePiece[]): void {
    for (let i = 0; i < linePieces.length; i++) {
      for (let j = i + 1; j < linePieces.length; j++) {
        if (this.tooClose(linePieces[i], linePieces[j])) {
          linePieces.splice(j, 1);
          j--;
        }
      }
    }
  }

  private plateToSlots(linePieces: LinePiece[], slots: LinePiece[][]): void {
    for (const linePiece of linePieces) {
      let isPlaced = false;
      for (const slot of slots) {
        if (slot.findIndex((s) => s.intersect(linePiece)) === -1) {
          isPlaced = true;
          slot.push(linePiece);
          break;
        }
      }

      if (!isPlaced) {
        slots.push([linePiece]);
      }
    }
  }

  constructor(rooms: Room[]) {
    // first calculate the widths
    {
      const linePieces = rooms
        .filter((r) => r.width > 500 && r.height > 500)
        .map((r) => new LinePiece(r.bbox.x1, r.bbox.x2))
        .sort((a, b) => b.length - a.length);

      this.handleTooClose(linePieces);
      this.plateToSlots(linePieces, this.widths);
    }

    // then calculate the heights
    {
      const linePieces = rooms
        .filter((r) => r.width > 500 && r.height > 500)
        .map((r) => new LinePiece(r.bbox.y1, r.bbox.y2))
        .sort((a, b) => b.length - a.length);

      this.handleTooClose(linePieces);
      this.plateToSlots(linePieces, this.heights);
    }
  }
}
