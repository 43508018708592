import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import { InspectionTemplateQuestion } from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-checklist',
  templateUrl: './checklist.component.html',
  styleUrl: './checklist.component.scss',
})
export class ChecklistComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  potentialFieldName: string = '';
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => !this.first,
        action: () => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.last,
        action: () => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.delete.emit(),
      },
      {
        divider: true,
      },
    ];
  }

  get radio(): boolean {
    return this.question.property.property_type === 'RadioList' || false;
  }

  get fields(): string[] {
    return this.question.property.possible_values || [];
  }

  set fields(fields: string[]) {
    this.question.property.possible_values = fields;
  }

  addField(): void {
    this.fields.push(this.potentialFieldName);
    this.potentialFieldName = '';
  }

  removeField(field: string): void {
    this.fields = this.fields.filter((otherField) => otherField != field);
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  trackByFn(index: number): number {
    return index;
  }
}
