import { animate, state, style, transition, trigger } from '@angular/animations';
import { booleanAttribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question, RequestContentItem, Section, SectionItem } from '../../models';
import { WorkspaceDetailService } from '../../services/workspace-detail.service';
import { QuestionType } from '@api-clients/workspace';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ContextMenuItem } from '@services/context.service';

@Component({
  selector: 'app-workspace-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'out',
        style({
          height: '*',
        })
      ),
      state(
        'in',
        style({
          height: '0',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class WorkspaceSectionComponent implements OnInit {
  readonly QuestionType = QuestionType;
  @Input() section!: Section;
  @Input({ transform: booleanAttribute }) prevIsFolder: boolean = false;
  @Input({ transform: booleanAttribute }) nextIsFolder: boolean = false;
  @Input() sectionIndex: number = 0;
  @Input() isLast: boolean = false;
  @Output() openSection = new EventEmitter();
  @Output() deleteSection = new EventEmitter();

  edit = false;
  expanded: boolean = true;
  menu: ContextMenuItem[] = [];

  constructor(public workspaceDetailService: WorkspaceDetailService) {
    workspaceDetailService.edit.subscribe((edit) => {
      this.edit = edit;
    });
  }

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => this.sectionIndex > 0,
        action: () => this.moveUp(),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.isLast,
        action: () => this.moveDown(),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.deleteSection.emit(),
      },
    ];
  }

  moveUp(): void {
    this.workspaceDetailService.currentSection?.updateChildPosition(
      this.sectionIndex,
      this.sectionIndex - 1
    );
    this.buildMenu();
  }

  moveDown(): void {
    this.workspaceDetailService.currentSection?.updateChildPosition(
      this.sectionIndex,
      this.sectionIndex + 1
    );
    this.buildMenu();
  }

  moveItem(movement: number, itemIndex: number): void {
    this.section.updateChildPosition(itemIndex, itemIndex + movement);
  }

  get active(): boolean {
    return this.workspaceDetailService.activeSection === this.section;
  }

  sectionClick(event, fromArrow): void {
    if (!fromArrow && this.edit) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    if (this.section.isFolder) {
      this.openSection.emit();
    } else {
      this.expanded = !this.expanded;
    }
  }

  itemDropped(event: CdkDragDrop<Section, Section, SectionItem>): void {
    if (event.previousContainer !== event.container) {
      // we dragged the item from one section to another. So we need to transfer the section item here.
      this.workspaceDetailService.currentWorkspace?.transferSectionItem(
        event.item.data.id,
        this.section.id,
        event.previousIndex,
        event.currentIndex
      );
      this.section.updateParentSection(event.currentIndex, this.section.id);
      return;
    }

    if (event.previousIndex === event.currentIndex) return;
    this.section.updateChildPosition(event.previousIndex, event.currentIndex);
  }

  get animationState(): string {
    //need to be derived from section info
    return this.expanded ? 'out' : 'in';
  }

  update(): void {
    this.section.update();
  }

  // TODO: Can we find a generic way of deleting section children?
  deleteChild(child: SectionItem): void {
    if (child instanceof Section) this.section.deleteSection(child.id);
    else if (child instanceof Question) this.section.deleteQuestion(child.id);
    else if (child instanceof RequestContentItem) this.section.deleteFileRequest(child.id);
  }

  get progressPercentage(): number {
    let length = 0;
    let completed = 0;

    this.section.allItems.forEach((item) => {
      if (item.isQuestion() || item.isFileRequest()) {
        length++;
        if ((item as Question | RequestContentItem).isCompleted) {
          completed++;
        }
      }
    });

    return Math.round((completed / length) * 100);
  }
}
