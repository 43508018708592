import { Box3, DirectionalLight, Mesh, PlaneGeometry, Scene, ShadowMaterial, Vector3 } from 'three';

export function addLightsToScene(scene: Scene): void {
  const light1 = new DirectionalLight(0xffffdf, 2.4);
  light1.position.set(0, 200, 400);
  const light2 = new DirectionalLight(0xfff5c7, 1.8);
  light2.position.set(500, 100, 0);
  const light3 = new DirectionalLight(0xfff5c7, 1.5);
  light3.position.set(0, 100, -500);
  const light4 = new DirectionalLight(0xfff5c7, 1.5);
  light4.position.set(-500, 300, 500);

  scene.add(light1, light2, light3, light4);
}

export function configDirectionalLight(boundingBox: Box3): DirectionalLight {
  const normLightDir = new Vector3(-1, -1, -1).normalize();
  const normRightDir = new Vector3(1, 0, -1).normalize();
  const normTopDir = new Vector3(-1, -1, -1).normalize().applyAxisAngle(normRightDir, Math.PI / 2);
  const points = boundingBoxToVertices(boundingBox);

  const range_max = points
    .map(
      (p) =>
        // Project points on floor in direction of light
        // noinspection JSSuspiciousNameCombination
        new Vector3(p.x - p.y + boundingBox.min.y, boundingBox.min.y, p.z - p.y + boundingBox.min.y)
    )
    .map((p) => p.dot(normLightDir)) // Calculate distance to origin in direction of light
    .reduce((a, b) => Math.max(a, b));
  const range_min = points.map((p) => p.dot(normLightDir)).reduce((a, b) => Math.min(a, b));
  const top = points.map((p) => p.dot(normTopDir)).reduce((a, b) => Math.max(a, b));
  const bottom = points.map((p) => p.dot(normTopDir)).reduce((a, b) => Math.min(a, b));
  const right = points.map((p) => p.dot(normRightDir)).reduce((a, b) => Math.max(a, b));
  const left = points.map((p) => p.dot(normRightDir)).reduce((a, b) => Math.min(a, b));
  const directionalLight = new DirectionalLight(0xffffff, 0.7);
  directionalLight.position.set(-1, -1, -1);
  directionalLight.position.setLength(range_min);
  directionalLight.castShadow = true;
  directionalLight.shadow.bias = -0.002;
  directionalLight.shadow.camera.bottom = bottom;
  directionalLight.shadow.camera.top = top;
  directionalLight.shadow.camera.left = left;
  directionalLight.shadow.camera.right = right;
  directionalLight.shadow.camera.near = 0;
  directionalLight.shadow.camera.far = range_max - range_min;
  directionalLight.shadow.mapSize.set(12048, 12048);
  return directionalLight;
}

export function createShadowCatcher(boundingBox: Box3): Mesh {
  const size = boundingBox.getSize(new Vector3());
  const center = boundingBox.getCenter(new Vector3());
  const geometry = new PlaneGeometry(size.x + size.y, size.z + size.y);
  geometry.rotateX(-Math.PI / 2);
  geometry.translate(center.x - size.y / 2, boundingBox.min.y, center.z - size.y / 2);
  const material = new ShadowMaterial();
  material.opacity = 0.2;
  const plane = new Mesh(geometry, material);
  plane['name'] = 'shadowCatcher';
  plane.receiveShadow = true;
  return plane;
}

export function boundingBoxToVertices(box: Box3): Vector3[] {
  const min = box.min;
  const max = box.max;
  return [
    new Vector3(min.x, min.y, min.z),
    new Vector3(min.x, min.y, max.z),
    new Vector3(min.x, max.y, min.z),
    new Vector3(min.x, max.y, max.z),
    new Vector3(max.x, min.y, min.z),
    new Vector3(max.x, min.y, max.z),
    new Vector3(max.x, max.y, min.z),
    new Vector3(max.x, max.y, max.z),
  ];
}
