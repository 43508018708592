import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from '../../models';
import { ContextMenuItem } from '@services/context.service';

@Component({
  selector: 'app-workspace-open-question',
  templateUrl: './open-question.component.html',
  styleUrls: ['./open-question.component.scss'],
})
export class WorkspaceOpenQuestionComponent implements OnInit {
  @Input() question!: Question;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => !this.first,
        action: () => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.last,
        action: () => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.delete.emit(),
      },
      {
        divider: true,
      },
      {
        name: 'is-required',
        checked: () => this.question.isRequired,
        action: () => this.toggleRequired(),
      },
      {
        name: 'show-units',
        checked: () => this.question.properties.showUnits,
        action: () => this.toggleUnits(),
      },
    ];
  }

  toggleUnits(): void {
    this.question.properties.showUnits = !this.question.properties.showUnits;
    this.question.update();
  }

  toggleRequired(): void {
    this.question.isRequired = !this.question.isRequired;
    this.question.update();
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }
}
