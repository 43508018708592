import { getBBox, toSvgPoints } from '@shared/components/floor-plan/utils';

export class Wall {
  public readonly polygons: { x: number; y: number }[];
  public readonly bbox: { x1: number; y1: number; x2: number; y2: number };
  public readonly points: string;
  public readonly bimId: string;

  constructor(polygons: { x: number; y: number }[], bimId: string) {
    this.polygons = polygons;
    this.bbox = getBBox(this.polygons);
    this.points = toSvgPoints(this.polygons);
    this.bimId = bimId;
  }
}
