<div
  class="section"
  cdkDrag
  [cdkDragDisabled]="!edit"
  [class.deactive]="!expanded"
  [class.active]="active"
  [class.edit]="edit"
  [class.section--folder]="section.isFolder"
  [class.folder-next]="nextIsFolder"
  [class.folder-prev]="prevIsFolder"
  [contextMenu]="menu">
  <button (click)="sectionClick($event, false)" class="section__top">
    <span class="section__top__drag" cdkDragHandle>
      <span></span>
      <span></span>
    </span>

    <div class="section__top__folder" *ngIf="section.isFolder">
      <span class="material-symbols-rounded">folder</span>
    </div>

    <span class="section__top__title" *ngIf="!edit">{{ section.name }}</span>
    <input
      type="text"
      class="section__top__input"
      [(ngModel)]="section.name"
      (blur)="update()"
      *ngIf="edit"
      placeholder="{{ 'section-name' | translate }}" />

    <button class="section__top__arrow" (click)="sectionClick($event, true)">
      <span class="material-symbols-rounded">
        {{ section.isFolder ? 'chevron_right' : 'expand_more' }}
      </span>
    </button>

    <app-three-dot [menu]="menu"></app-three-dot>
    <app-workspace-progress [progress]="progressPercentage"></app-workspace-progress>
  </button>

  <div [@slideInOut]="animationState" class="section__content" *ngIf="!section.isFolder">
    <div cdkDropList class="item-list" (cdkDropListDropped)="itemDropped($event)">
      <app-section-item
        *ngFor="let item of this.section.items; let i = index"
        cdkDrag
        [cdkDragData]="this.section"
        [cdkDragDisabled]="!edit"
        class="section__content__item"
        [edit]="edit"
        [item]="item"
        [first]="i === 0"
        [last]="i === this.section.items.length - 1"
        (move)="moveItem($event, i)"
        (deleteChild)="deleteChild($event)">
        <div class="section__content__drag" cdkDragHandle>
          <span></span>
          <span></span>
        </div>
      </app-section-item>
    </div>
  </div>
</div>
