<div class="floor-plan-viewer">
  <div class="big-spinner" *ngIf="!floorPlan.currentLevel || this.isSaving"></div>

  <div class="sidebar" *ngIf="floorPlan.currentLevel">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

    <div class="big-input select-version">
      <select
        [(ngModel)]="this.floorPlan.currentLevel"
        (change)="unselect()"
        id="current_level_select">
        <option [ngValue]="level" *ngFor="let level of this.floorPlan.levels">
          {{ level.description }}
        </option>
      </select>
    </div>

    <router-outlet></router-outlet>
  </div>

  <div class="floor-plan__container">
    <app-floor-plan
      #floorPlan
      [building_id]="building_id"
      (bimIdSelected)="bimIdSelected($event)"
      (unselected)="unselect()"></app-floor-plan>

    <div class="button-group">
      <button
        (click)="downloadSvg()"
        class="button-secondary button--has-icon"
        *ngIf="floorPlan.currentLevel">
        <span class="material-symbols-rounded">download</span>
        {{ 'download' | translate }}
      </button>
      <button
        class="button-primary cta-button"
        *ngIf="!shoppingCartVisible"
        [disabled]="shoppingCartCount === 0"
        [class.animate]="context.animate"
        (click)="showShoppingCart()">
        ({{ shoppingCartCount }}) {{ 'changes' | translate }}
      </button>
      <button
        class="button-primary cta-button"
        *ngIf="shoppingCartVisible"
        [disabled]="shoppingCartCount === 0 || isSaving"
        (click)="saveShoppingCart()">
        <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
        {{ 'save' | translate }}
      </button>
    </div>

    <div class="navigation-group" *ngIf="floorPlan.currentLevel">
      <button (click)="floorPlan.resetViewPort()">
        <span class="material-symbols-rounded">reset_image</span>
      </button>

      <button (click)="floorPlan.zoomOut()">
        <span class="material-symbols-rounded">zoom_out</span>
      </button>
      <button (click)="floorPlan.zoomIn()">
        <span class="material-symbols-rounded">zoom_in</span>
      </button>
    </div>
  </div>
</div>
