import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceOverviewComponent } from './views/overview-page/workspace-overview.component';
import { WorkspaceDetailComponent } from './views/detail-page/workspace-detail.component';
import { WorkspaceSectionComponent } from './components/section/section.component';
import { WorkspaceProgressComponent } from './components/progress/progress.component';
import { WorkspaceOpenQuestionComponent } from './components/open-question/open-question.component';
import { WorkspaceTopBarComponent } from './components/top-bar/top-bar.component';
import { WorkspaceFileUploadComponent } from './components/file-upload/file-upload.component';
import { WorkspaceChecklistComponent } from './components/checklist/checklist.component';
import { WorkspaceToolboxComponent } from './components/toolbox/toolbox.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { httpTranslateLoader } from '../app.module';
import { SharedModule } from '@shared/shared.module';
import { Configuration as WorkspaceConfig, WorkspaceApiModule } from '@api-clients/workspace';
import { environment } from '@env/environment';
import { FormsModule } from '@angular/forms';
import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { SectionItemComponent } from './components/section-item/section-item.component';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { WorkspaceListComponent } from './components/workspace-list/workspace-list.component';
import { WorkspaceStatusBubbleComponent } from './components/status-bubble/status-bubble.component';
import { WorkspaceSelectComponent } from './components/workspace-select-dialog/workspace-select-dialog.component';

import { WorkspacesRoutingModule } from './workspaces-routing.module';

const getConfig = (path: string): { basePath: string } => ({
  basePath: `${environment.API_BASE_PATH}/${path}`,
});

@NgModule({
  declarations: [
    WorkspaceOverviewComponent,
    WorkspaceDetailComponent,
    WorkspaceSectionComponent,
    WorkspaceProgressComponent,
    WorkspaceOpenQuestionComponent,
    WorkspaceTopBarComponent,
    WorkspaceListComponent,
    WorkspaceStatusBubbleComponent,
    WorkspaceSelectComponent,
    WorkspaceFileUploadComponent,
    WorkspaceChecklistComponent,
    WorkspaceToolboxComponent,
    SectionItemComponent,
    FileSizePipe,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
    WorkspacesRoutingModule,
    WorkspaceApiModule.forRoot(() => new WorkspaceConfig(getConfig('workspaces'))),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CdkDropListGroup,
  ],
  exports: [
    WorkspaceSectionComponent,
    WorkspaceProgressComponent,
    WorkspaceOpenQuestionComponent,
    WorkspaceTopBarComponent,
    WorkspaceListComponent,
    WorkspaceStatusBubbleComponent,
    WorkspaceSelectComponent,
    WorkspaceFileUploadComponent,
    WorkspaceChecklistComponent,
    WorkspaceToolboxComponent,
  ],
})
export class WorkspacesModule {}
