<div class="file-specs" [class.hide]="sliderExpanded">
  <div class="file-specs__wrapper">
    <div class="file-specs__header">
      <h3 class="file-specs__header__title">{{ file?.file_name }}</h3>
    </div>

    <div class="file-specs__buttons">
      <a
        class="button-primary button--has-icon"
        href="{{ fileUrl }}"
        target="_self"
        download="{{ file?.file_name }}">
        <span class="material-symbols-rounded">download</span>
        {{ 'download' | translate }}
      </a>
    </div>

    <h5 class="spec-title">{{ 'file-details' | translate }}</h5>
    <ul>
      <li *ngIf="timeStamp">
        <span class="label">{{ 'file-added-on' | translate }}</span>
        {{ timeStamp | date: 'dd-MM-yyyy HH:mm' }}
      </li>
      <li>
        <span class="label">{{ 'file-type' | translate }}</span>
        {{ fileType }}
      </li>
      <li>
        <span class="label">{{ 'file-size' | translate }}</span>
        {{ humanReadableSize(file?.file_size) }}
      </li>
      <li *ngIf="user">
        <span class="label">{{ 'file-owner' | translate }}</span>
        {{ user }}
      </li>
      <li *ngIf="file?.description">
        <span class="label">{{ 'file-description' | translate }}</span>
        {{ file?.description }}
      </li>
    </ul>

    <h5 class="spec-title">{{ 'file-preview' | translate }}</h5>
  </div>

  <!--  Demo files-->
  <!--https://angular-doc-viewer.firebaseapp.com/assets/file_example_PPT_250kB.ppt-->
  <!--https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4-->
  <!--https://drive.google.com/file/d/0B5ImRpiNhCfGZDVhMGEyYmUtZTdmMy00YWEyLWEyMTQtN2E2YzM3MDg3MTZh/preview-->
  <!--https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf-->

  <app-file-preview
    [fileUrl]="fileUrl"
    [fileSize]="file?.file_size ?? 0"
    [fileName]="file?.file_name"></app-file-preview>
  <!--
  <ng-content></ng-content>
--></div>
