import { Component, Input, OnInit } from '@angular/core';
import {
  DossierDocument,
  DossierFile,
  DossierImage,
  Inspection,
  InspectionsService,
  TimeLineDto,
  TimeLineType,
} from '@api-clients/dossier';
import { EnrichedTimeLineDto } from '../EnrichedTimeLineDto';
import { lastValueFrom } from 'rxjs';
import { isBooleanAnswer, isImagesAnswer, isNumberAnswer, isTextAnswer } from './types';
import { environment } from '@env/environment';
import { DossierService } from '@services/dossier.service';

@Component({
  selector: 'app-inspection-result',
  templateUrl: './inspection-result.component.html',
  styleUrl: './inspection-result.component.scss',
})
export class InspectionResultComponent implements OnInit {
  @Input() item: EnrichedTimeLineDto = {} as EnrichedTimeLineDto;
  @Input() user: string | undefined = '';
  @Input() timeStamp: string | undefined = '';

  protected selectedFile: DossierImage | DossierFile | DossierDocument | undefined;
  protected selectedFileUrl: string | undefined;

  constructor(
    private readonly inspectionsService: InspectionsService,
    private readonly dossierService: DossierService
  ) {}

  protected inspection: Inspection | undefined = undefined;

  async ngOnInit(): Promise<void> {
    // Then get the inspection result for this timeline item
    this.inspection = await lastValueFrom(
      this.inspectionsService.dossiersDossierIdInspectionIdGet(
        this.item.dossier_id,
        this.item.item_id
      )
    );
  }

  protected async openImage(item: TimeLineDto, image_id: string): Promise<void> {
    this.selectedFileUrl = await this.dossierService.getImagePresignedUrl(
      item.dossier_id,
      image_id
    );
    this.selectedFile = await this.dossierService.getImage(item.dossier_id, image_id);
  }

  protected deselectFile(): void {
    this.selectedFile = undefined;
    this.selectedFileUrl = undefined;
  }

  protected keyDown(e: KeyboardEvent): void {
    if (e.key == 'Escape') {
      this.deselectFile();
    }
  }

  protected readonly environment = environment;
  protected readonly TimeLineType = TimeLineType;
  protected readonly isImagesAnswer = isImagesAnswer;
  protected readonly isTextAnswer = isTextAnswer;
  protected readonly isNumberAnswer = isNumberAnswer;
  protected readonly isBooleanAnswer = isBooleanAnswer;
}
