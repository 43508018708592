import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultipleChoiceItem, Question } from '../../models';
import { ContextMenuItem } from '@services/context.service';

@Component({
  selector: 'app-workspace-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
})
export class WorkspaceChecklistComponent implements OnInit {
  @Input() question!: Question;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  potentialFieldName: string = '';
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => !this.first,
        action: () => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.last,
        action: () => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.delete.emit(),
      },
      {
        divider: true,
      },
      {
        name: 'is-required',
        checked: () => this.question.isRequired,
        action: () => this.toggleRequired(),
      },
    ];
  }

  get radio(): boolean {
    return this.question.properties?.radio || false;
  }

  get fields(): MultipleChoiceItem[] {
    return this.question.value || [];
  }

  set fields(fields: MultipleChoiceItem[]) {
    this.question.value = fields;
    this.question.update();
  }

  addField(): void {
    this.fields = [
      ...this.fields,
      { name: this.potentialFieldName, checked: false, isOtherOption: false },
    ];
    this.potentialFieldName = '';
  }

  removeField(field: MultipleChoiceItem): void {
    this.fields = this.fields.filter((otherField) => otherField != field);
  }

  toggleRequired(): void {
    this.question.isRequired = !this.question.isRequired;
    this.question.update();
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  /// Manually manages the state of the radiobuttons / checkboxes. This cumbersome syntax
  /// is necessary to always trigger the angular change detection.
  field_clicked(field: MultipleChoiceItem, event: Event): void {
    event.preventDefault();
    if (this.radio) {
      // Radio buttons
      this.fields = this.fields.map((otherField) => {
        if (field == otherField) {
          // Switch the state of the clicked field. However, only allow deselect when editing
          return { ...otherField, checked: !otherField.checked || !this.edit };
        } else {
          return { ...otherField, checked: false };
        }
      });
    } else {
      // Check boxes
      this.fields = this.fields.map((otherField) => {
        if (field == otherField) {
          return { ...otherField, checked: !otherField.checked };
        } else {
          return otherField;
        }
      });
    }
  }
}
