<div class="workspace-list">
  <div class="workspace-list__header">
    <h3>{{ listTitle }}</h3>
    <button *ngIf="linkAction" (click)="linkAction.emit()">{{ linkTitle }}</button>
  </div>
  <div
    class="workspace-list__item"
    *ngFor="let workspace of workspaces; let i = index"
    tabindex="-1"
    aria-hidden="true"
    (click)="navigateToWorkspace(workspace.id)"
    (keydown)="navigateToWorkspace(workspace.id)"
    [contextMenu]="menu"
    (mousedown)="setActive(workspace.id)">
    <img src="http://placekitten.com/{{ i + 1 }}00/{{ i + 1 }}00" alt="test" />

    <div class="workspace-list__item__text">
      <span>{{ workspace.name }}</span>
      <span class="owner">Sir Meowsalot</span>
    </div>

    <app-workspace-status-bubble [status]="testStatus[i]"></app-workspace-status-bubble>

    <div class="workspace-list__item__date">8 maart</div>
  </div>
</div>
