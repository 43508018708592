import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkspaceOverviewComponent } from './views/overview-page/workspace-overview.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { WorkspaceDetailComponent } from './views/detail-page/workspace-detail.component';

const routes: Routes = [
  {
    path: 'forms',
    component: WorkspaceOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'buildings/:id/forms',
    component: WorkspaceOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'buildings/:id/forms/:workspaceId',
    component: WorkspaceDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'forms/:workspaceId',
    component: WorkspaceDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkspacesRoutingModule {}
