import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BuildingMenuService } from '@shared/services/building-menu.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BuildingOverviewService } from '@services/building-overview.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { Subject, takeUntil } from 'rxjs';

export interface menuItems {
  label?: string;
  icon?: string;
  route?: string[];
  subActive?: boolean;
  subMenu?: menuItems[];
}

@Component({
  selector: 'app-building-menu',
  templateUrl: './building-menu.component.html',
  styleUrls: ['./building-menu.component.scss'],
})
export class BuildingMenuComponent implements OnChanges, OnDestroy {
  @Input() buildingId?: string;
  @Output() expandMenu: EventEmitter<void> = new EventEmitter<void>();

  building?: BuildingOverviewEntry;
  expandState: boolean = true;
  active: boolean = false;

  menuItems: menuItems[] = [
    /*    {
      label: 'forms',
      icon: 'article',
      route: ['forms'],
      subActive: true,
    },*/
    {
      label: '3d-model',
      icon: 'deployed_code',
      route: ['model', 'details'],
    },
    {
      label: 'floor-plan',
      icon: 'floor',
      route: ['floor-plan', 'details'],
    },
    {
      label: 'dossier',
      icon: 'Package',
      route: ['dossier'],
    },
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private buildingMenuService: BuildingMenuService,
    private buildingOverviewService: BuildingOverviewService,
    private router: Router
  ) {
    this.buildingMenuService.buildingMenuStatus$.pipe(takeUntilDestroyed()).subscribe((status) => {
      this.active = status.active;
      this.expandState = status.expand;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['buildingId']) {
      this.buildingOverviewService
        .getBuildingById(changes['buildingId'].currentValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe((next) => {
          this.building = next;
        });
    }
  }

  navigate(routeParts?: string[]): void {
    if (routeParts === undefined) {
      return;
    }
    void this.router.navigate(['buildings', this.buildingId, ...routeParts]);
  }

  toggleExpand(): void {
    this.buildingMenuService.toggleExpand();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
