import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StyleguideComponent } from './views/styleguide/styleguide.component';
import { HomeComponent } from './views/home/home.component';
import { ModelViewerExternalComponent } from './views/model-viewer-external/model-viewer-external.component';
import { ModelViewerComponent } from './views/model-viewer/model-viewer.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { AuthorizedComponent } from './views/callback/authorized.component';
import { ContextPopupComponent } from './views/model-viewer/context-popup/context-popup.component';
import { ChangesSummaryComponent } from './views/model-viewer/changes-summary/changes-summary.component';
import { FloorPlanViewerComponent } from './views/floor-plan-viewer/floor-plan-viewer.component';

const routes: Routes = [
  {
    path: 'model-viewer',
    component: ModelViewerComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'model-viewer-external/:id',
    component: ModelViewerExternalComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  { path: '', pathMatch: 'full', redirectTo: 'buildings' },
  { path: 'callback', component: AuthorizedComponent },
  {
    path: 'buildings/:building_id/model',
    component: ModelViewerComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: 'details',
        component: ContextPopupComponent,
      },
      {
        path: 'changes',
        component: ChangesSummaryComponent,
      },
    ],
  },
  {
    path: 'buildings/:building_id/models/:model_id',
    component: ModelViewerComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'buildings/:building_id/floor-plan',
    component: FloorPlanViewerComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: 'details',
        component: ContextPopupComponent,
      },
      {
        path: 'changes',
        component: ChangesSummaryComponent,
      },
    ],
  },
  {
    path: 'buildings',
    component: HomeComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'styleguide',
    component: StyleguideComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
