import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ProductsPopup {
  active: boolean;
  animate: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsAddPopupService {
  productsPopup: ProductsPopup = { active: false, animate: false };
  productsPopupChange: Subject<ProductsPopup> = new Subject<ProductsPopup>();
  hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  shown: EventEmitter<boolean> = new EventEmitter<boolean>();
  initialGtinProducts: EventEmitter<string[]> = new EventEmitter<string[]>();

  get(): ProductsPopup {
    return this.productsPopup;
  }

  hide(): void {
    this.productsPopup.active = false;
    this.hidden.emit(true);
  }

  show(initialGtin: string[]): void {
    this.productsPopup.active = true;
    this.initialGtinProducts.emit(initialGtin);
    this.shown.emit(true);
  }
}
