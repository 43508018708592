import { getBBox } from '@shared/components/floor-plan/utils';

export class Door {
  public readonly polygons: { x: number; y: number }[];
  public readonly bbox: { x1: number; y1: number; x2: number; y2: number };
  public readonly rotate: number;
  public readonly width: number;
  public readonly height: number;
  public readonly vertical: boolean;
  public readonly bimId: string;

  constructor(polygons: { x: number; y: number }[], angle: number, bimId: string) {
    this.polygons = polygons;
    this.bbox = getBBox(this.polygons);
    this.rotate = angle;
    this.width = this.bbox.x2 - this.bbox.x1;
    this.height = this.bbox.y2 - this.bbox.y1;
    this.vertical = this.height > this.width;
    this.bimId = bimId;
  }
}
