import { Room } from '@shared/components/floor-plan/room';
import { Door } from '@shared/components/floor-plan/door';
import { Window } from '@shared/components/floor-plan/window';
import { Wall } from '@shared/components/floor-plan/wall';
import { Dimensions } from '@shared/components/floor-plan/dimensions';

export class Level {
  public readonly rooms: Room[];
  public readonly description: string;
  public readonly doors: Door[];
  public readonly windows: Window[];
  public readonly walls: Wall[];
  public readonly dimensions: Dimensions;

  constructor(
    description: string,
    rooms: Room[],
    doors: Door[],
    windows: Window[],
    walls: Wall[],
    dimensions: Dimensions
  ) {
    this.rooms = rooms;
    this.description = description;
    this.doors = doors;
    this.windows = windows;
    this.walls = walls;
    this.dimensions = dimensions;
  }
}
