<div class="checklist" [class.edit]="edit" [contextMenu]="menu">
  <h3 class="checklist__title">
    {{ edit || !this.question.label ? ('checklist' | translate) : this.question.label }}
    {{ this.question.isRequired ? '*' : '' }}
  </h3>

  <div class="checklist__wrapper">
    <div class="checklist__input big-input" *ngIf="edit">
      <input value="Wat vind u hier van?" type="text" name="input" id="input" autocomplete="off" />
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>

    <div class="checklist__list">
      <div class="list-item" *ngFor="let field of fields">
        <label [ngClass]="radio ? 'radiocontainer' : 'checkboxcontainer'">
          <input
            name="field"
            type="checkbox"
            [checked]="field.checked"
            (click)="field_clicked(field, $event)" />
          <span [ngClass]="radio ? 'radiomark' : 'checkmark'"></span>
        </label>

        <span class="list-item__text" *ngIf="!edit">{{ field.name }}</span>
        <input
          *ngIf="edit"
          class="list-item__input"
          type="text"
          [(ngModel)]="field.name"
          (blur)="question.update()" />
        <button
          *ngIf="edit"
          class="material-symbols-rounded list-item__remove"
          (click)="removeField(field)">
          close
        </button>
      </div>

      <div class="list-item new">
        <label [ngClass]="radio ? 'radiocontainer' : 'checkboxcontainer'">
          <input type="checkbox" />
          <span [ngClass]="radio ? 'radiomark' : 'checkmark'"></span>
        </label>

        <span class="list-item__text" *ngIf="!edit">{{ 'add-check' | translate }}</span>
        <input
          type="text"
          class="list-item__input"
          [(ngModel)]="potentialFieldName"
          placeholder="{{ 'add-check' | translate }}"
          *ngIf="edit"
          (keyup.enter)="addField()" />

        <button class="material-symbols-rounded list-item__add" (click)="addField()">add</button>
      </div>
    </div>
  </div>
</div>
