import { BimElementDto } from '@api-clients/bim';
import { Product } from '@api-clients/product';

export class ChangedElement {
  id: string;
  element: BimElementDto;
  changes: (Change | CategoryChange)[];
  delete?: boolean = false;

  constructor(id: string, element: BimElementDto, changes: (Change | CategoryChange)[]) {
    this.id = id;
    this.element = element;
    this.changes = changes;
  }
}

export class Change {
  propertyName: string;
  newValue: string | number | boolean | undefined | Product[];
  oldValue: string | number | boolean | undefined | Product[];

  constructor(
    propertyName: string,
    oldValue: string | number | boolean | undefined | Product[],
    newValue: string | number | boolean | undefined | Product[]
  ) {
    this.propertyName = propertyName;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}

export class CategoryChange {
  newValue: string | undefined;
  oldValue: string | undefined;

  constructor(oldValue: string | undefined, newValue: string | undefined) {
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}
