<div class="context-popup">
  <div class="context-popup__wrapper" [ngClass]="[context.active ? 'active' : '']">
    <div class="context-popup__header">
      <ng-container *ngIf="!categoryEdit">
        <h5>
          {{ element?.['category'] | translate }}
          <button (click)="editCategory()" *ngIf="!anyPropertyChanged">
            <span class="material-symbols-rounded">edit</span>
          </button>
        </h5>
      </ng-container>
      <div *ngIf="categoryEdit && !anyPropertyChanged">
        <label for="category" class="title">
          {{ 'category' | translate }}
        </label>

        <select [ngModel]="selectedCategory" (ngModelChange)="selectCategory($event)" id="category">
          <option *ngFor="let item of categories" [value]="item">
            {{ item | translate }}
          </option>
        </select>
      </div>
      <button (click)="closePopup()" (keydown)="closePopup()" class="material-symbols-rounded">
        close
      </button>
    </div>
    <div class="context-popup__content">
      <div *ngIf="elementProperties">
        <div *ngFor="let property of elementProperties | keyvalue">
          <div class="big-input" *ngIf="property.value.data_type === 'float'">
            <label class="title" for="{{ property.key }}">{{ property.key | translate }}</label>
            <input
              [type]="inputForType(property.value.data_type)"
              id="{{ property.key }}"
              [disabled]="categoryChanged"
              [(ngModel)]="property.value.value"
              (ngModelChange)="propertyChanged()"
            />

            <label for="{{ property.key }}" class="units">
              {{ property.value.unit }}
            </label>
          </div>
          <label
            class="checkboxcontainer big-checkbox"
            *ngIf="property.value.data_type === 'boolean'">
            {{ property.key || '' | translate }}
            <input
              type="checkbox"
              id="{{ property.key }}"
              [(ngModel)]="property.value.value"
              (ngModelChange)="propertyChanged()"
              [disabled]="categoryChanged"
            />
            <span class="checkmark"></span>
          </label>

          <div class="big-input" *ngIf="property.value.data_type === 'enum'">
            <label for="{{ property.key }}" class="title">
              {{ property.key | translate }}
            </label>

            <select
              id="{{ property.key }}"
              [(ngModel)]="property.value.value"
              (ngModelChange)="propertyChanged()"
              [disabled]="categoryChanged">
              <option [value]="undefined">{{ 'unset' | translate }}</option>
              <option *ngFor="let item of property.value.values" [value]="item">
                {{ item | translate }}
              </option>
            </select>
          </div>
        </div>

        <div *ngFor="let property of elementProperties | keyvalue">
          <div
            class="context-popup__products"
            *ngIf="property.value.data_type === 'products' && $any(property.value.value)?.length">
            <h4 class="context-popup__products__title">
              {{ 'added-products-title' | translate }}
            </h4>

            <ul class="context-popup__products__list">
              <li *ngFor="let product of $any(property.value.value); let i = index">
                <span class="description">{{ product['description'] }}</span>
                <button (click)="removeProduct(i)">
                  <span class="material-symbols-rounded">close</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="context-popup__buttons">
        <button (click)="productPopup()" class="add-products-button" *ngIf="this.elementProperties?.['products'] !== undefined">
          {{ 'add-products' | translate }}
        </button>

        <button (click)="addProperties()" class="button-primary save-button">
          {{ 'add' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="context-popup__added" [ngClass]="[context.animate ? 'animate' : '']"></div>
</div>

<app-products-add-popup (productsPopupChange)="addProducts($event)"></app-products-add-popup>
