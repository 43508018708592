import { Component } from '@angular/core';
import { ShoppingCartService } from '../shopping-cart.service';
import { CategoryChange, Change, ChangedElement } from './change';
import { BimElementDto } from '@api-clients/bim';
import { isBimObject, isBimRoom } from '../utils/types';

@Component({
  selector: 'app-changes-summary',
  templateUrl: './changes-summary.component.html',
  styleUrl: './changes-summary.component.scss',
})
export class ChangesSummaryComponent {
  protected changes: ChangedElement[] = [];

  constructor(private readonly shoppingCartService: ShoppingCartService) {
    this.shoppingCartService.content.subscribe((next) => {
      this.changes = next;
    });
  }

  removeChange(element: ChangedElement, change: Change | CategoryChange, event: Event): void {
    event.stopPropagation();
    element.delete = true;

    setTimeout(() => {
      this.shoppingCartService.remove(element, change);
    }, 400);
  }

  activateElement(element: ChangedElement): void {
    this.shoppingCartService.activateElement(element.id);
  }

  getTypeName(element: BimElementDto): string {
    if (isBimObject(element.element)) {
      return element.element.category.toString();
    }
    if (isBimRoom(element.element)) {
      return 'Room';
    }

    return 'Object';
  }

  protected isPropertyChange(change): boolean {
    return change instanceof Change;
  }

  protected isCategoryChange(change): boolean {
    return change instanceof CategoryChange;
  }
}
