import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkspaceDto } from '@api-clients/workspace';
import { Router } from '@angular/router';
import { ContextMenuItem } from '@services/context.service';

@Component({
  selector: 'app-workspace-list',
  templateUrl: './workspace-list.component.html',
  styleUrls: ['./workspace-list.component.scss'],
})
export class WorkspaceListComponent implements OnInit {
  @Input() workspaces: WorkspaceDto[] = [];
  @Input() listTitle: string = '';
  @Input() linkTitle: string = '';

  @Output() linkAction = new EventEmitter<WorkspaceDto>();
  @Output() deleteAction = new EventEmitter<WorkspaceDto>();

  menu: ContextMenuItem[] = [];
  activeForm: string = '';
  testStatus: Array<string> = ['recieved', 'draft', 'completed', 'sent-out'];

  constructor(private router: Router) {}

  async ngOnInit(): Promise<void> {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'Edit',
        icon: 'edit',
        action: () => this.navigateToWorkspace(this.activeForm),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => console.log('delete'),
      },
    ];
  }

  setActive(workspaceId: string): void {
    this.activeForm = workspaceId;
    this.buildMenu();
  }

  navigateToWorkspace(workspaceId: string): void {
    void this.router.navigate(['/forms/' + workspaceId]);
  }
}
