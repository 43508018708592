<div class="open-question" [class.edit]="edit" [contextMenu]="menu">
  <label for="input" class="open-question__title">
    {{ edit ? ('open-question' | translate) : question.label }}
    {{ question.isRequired ? '*' : '' }}
  </label>

  <div class="open-question__wrapper">
    <div class="open-question__input big-input">
      <input
        placeholder="{{ 'question-name' | translate }}"
        type="text"
        name="input"
        autocomplete="off"
        [(ngModel)]="question.label"
        (blur)="question.update()"
        (keyup.enter)="question.update()"
        *ngIf="edit" />
      <input
        type="text"
        name="input"
        autocomplete="off"
        [(ngModel)]="question.value"
        (blur)="question.update()"
        (keyup.enter)="question.update()"
        *ngIf="!edit" />

      <label
        for="units"
        class="open-question__units-title"
        *ngIf="edit && question.properties.showUnits">
        {{ 'units-title' | translate }}
      </label>

      <input
        placeholder="{{ 'units-placeholder' | translate }}"
        id="units"
        type="text"
        name="input"
        autocomplete="off"
        [(ngModel)]="question.properties.unitsValue"
        (blur)="question.update()"
        (keyup.enter)="question.update()"
        *ngIf="edit && question.properties?.showUnits" />

      <span class="units" *ngIf="!edit && question.properties?.showUnits">
        {{ question.properties.unitsValue }}
      </span>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>
  </div>
</div>
