import { Component } from '@angular/core';
import {
  InspectionsService,
  InspectionTemplate,
  InspectionTemplateSection,
} from '@api-clients/dossier';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ToastrService } from '@shared/services/toastr.service';

@Component({
  selector: 'app-inspection-template-detail',
  templateUrl: './inspection-template-detail.component.html',
  styleUrl: './inspection-template-detail.component.scss',
})
export class InspectionTemplateDetailComponent {
  protected inspectionTemplateId: string | undefined;
  protected edit = false;
  protected inspectionTemplate: InspectionTemplate;
  protected currentSection: InspectionTemplateSection | undefined;
  protected isSaving: boolean = false;

  protected breadcrumbTree = [
    {
      translate: 'inspection-templates',
      link: '/inspection_templates',
    },
    {
      translate: 'edit',
      link: '/inspection_templates/{id}/edit',
    },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly inspectionService: InspectionsService,
    private readonly userService: UserService,
    private readonly toastrService: ToastrService
  ) {
    this.inspectionTemplate = {
      id: '',
      description: '',
      definition: {
        inspection_name: '',
        sections: [],
      },
      timestamp_utc: '',
      organization_id: this.userService.organizationId,
    };

    this.inspectionTemplateId = route.snapshot.paramMap.get('id') ?? undefined;
    if (this.inspectionTemplateId) {
      this.inspectionService.templateIdGet(this.inspectionTemplateId).subscribe((next) => {
        this.inspectionTemplate = next;
        this.updateBreadcrumb();
      });
    }
  }

  changeSwitch(value): void {
    this.edit = value;
  }

  updateBreadcrumb(): void {
    this.breadcrumbTree = [
      {
        translate: 'inspection-templates',
        link: '/inspection_templates',
      },
      {
        translate: 'edit',
        link: `/inspection_templates/${this.inspectionTemplateId}/edit`,
      },
    ];
  }

  arrayMove(arr, fromIndex: number, toIndex: number): void {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  activateSection(section: InspectionTemplateSection): void {
    this.currentSection = section;
  }

  itemDropped(event: CdkDragDrop<InspectionTemplateSection>): void {
    console.warn('item dropped', event.previousIndex, event.currentIndex);
    if (event.previousIndex === event.currentIndex) return;
    this.arrayMove(
      this.inspectionTemplate.definition.sections,
      event.previousIndex,
      event.currentIndex
    );
  }

  async deleteSection(section: InspectionTemplateSection): Promise<void> {
    if (!this.currentSection || !section) return;
    // Update view so that the section is removed from the view before the request is sent
    this.inspectionTemplate.definition.sections =
      this.inspectionTemplate.definition.sections.filter((s) => s !== section);
  }

  save(): void {
    if (this.isSaving) return;
    this.isSaving = true;
    if (this.inspectionTemplateId === undefined) {
      // new template
      this.inspectionService
        .templatePost({
          description: this.inspectionTemplate.description,
          definition: this.inspectionTemplate.definition,
        })
        .subscribe((next) => {
          this.isSaving = false;
          this.toastrService.showSuccess(
            'dossier-module.template.save-success',
            'dossier-module.template.save-title'
          );
          void this.router.navigate(['..', 'edit', next.id], {
            relativeTo: this.route,
          });
        });

      return;
    }

    // changed template
    this.inspectionService
      .templateIdPut(this.inspectionTemplateId, {
        description: this.inspectionTemplate.description,
        definition: this.inspectionTemplate.definition,
      })
      .subscribe((next) => {
        this.inspectionTemplate = next;
        this.isSaving = false;
        this.toastrService.showSuccess(
          'dossier-module.template.save-success',
          'dossier-module.template.save-title'
        );
      });

    this.edit = false;
  }
}
