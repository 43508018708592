import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { Dossier } from '@api-clients/dossier';
import {
  AddressableUnitsService as AddressableUnitsService,
  BuildingModel,
  BuildingsService,
} from '@api-clients/real-estate';
import { BuildingMetadata } from '@core/models/building-model';
import { DossierService } from '@services/dossier.service';
import {
  AdresService,
  PandIdentificatieRequestParams,
  PandIOHal,
  PandService,
  VerblijfsobjectService,
} from '@api-clients/kadaster';
import { LngLat } from 'maplibre-gl';
import Proj4 from 'proj4';

export type CadastralId = string;

@Injectable({
  providedIn: 'root',
})
export class BuildingOverviewService {
  private ownedBuildings = new BehaviorSubject<BuildingOverviewEntry[]>([]);
  public ownedBuildings$ = this.ownedBuildings.asObservable();

  constructor(
    private readonly dossierService: DossierService,
    private readonly buildingsService: BuildingsService,
    private readonly addressableUnitsService: AddressableUnitsService,
    private readonly pandService: PandService,
    private readonly adresService: AdresService,
    private readonly verblijfsObjectService: VerblijfsobjectService
  ) {
    void this.init_buildings();
  }

  private async init_buildings(): Promise<void> {
    const entries = await this.getBuildingOverviewEntries();
    this.ownedBuildings.next(entries);
  }

  public async getBuildingOverviewEntries(): Promise<BuildingOverviewEntry[]> {
    const dossiers: Dossier[] = await this.dossierService.getDossiersForCurrentOrganization();
    const buildings: BuildingModel[] = await lastValueFrom(
      this.buildingsService.includingAddressesGet(dossiers.map((d) => d.building_id))
    );

    const entries: BuildingOverviewEntry[] = [];
    for (const building of buildings) {
      const metadata: BuildingMetadata | undefined = new BuildingMetadata(undefined, undefined);

      metadata.addresses = building.addresses;
      const firstAddress = building.addresses[0];
      if (firstAddress) {
        metadata.address = firstAddress.address + ' ' + firstAddress.house_number;
        metadata.city = firstAddress.place;
        metadata.postalCode = firstAddress.zip_code;
      }
      if (building.coordinates) {
        metadata.location = new LngLat(building.coordinates.lng, building.coordinates.lat);
      }

      const dossier = dossiers.find((d) => d.building_id === building.id);
      if (!dossier) throw new Error('Dossier not found');

      entries.push({
        real_estate_id: building.id,
        external_id: building.external_id,
        buildingMetadata: metadata,
        dossier_id: dossier.id,
        bim_id: dossier.linked_bim_ids[0],
      });
    }
    return entries;
  }

  private async generateBuildingMetadata(cadastralId: string): Promise<BuildingMetadata> {
    const [pandInfo, bevraagAdressen, bevraagVerblijfsObjecten] = await Promise.all([
      this.getPandInfo(cadastralId),
      lastValueFrom(this.adresService.bevraagAdressen({ pandIdentificatie: cadastralId })),
      lastValueFrom(
        this.verblijfsObjectService.zoekVerblijfsobjecten({
          pandIdentificatie: cadastralId,
          huidig: true,
          acceptCrs: 'epsg:28992',
        })
      ),
    ]);

    return new BuildingMetadata(
      bevraagAdressen?._embedded?.adressen,
      pandInfo,
      bevraagVerblijfsObjecten?._embedded?.verblijfsobjecten?.at(0)
    );
  }

  private async getPandInfo(cadastralId: string): Promise<PandIOHal> {
    const query = {
      identificatie: cadastralId,
      acceptCrs: 'epsg:28992',
    } as PandIdentificatieRequestParams;

    const pandInfo = this.pandService.pandIdentificatie(query);
    return await lastValueFrom(pandInfo);
  }

  async getBuildingByCadId(cadastralId: CadastralId): Promise<BuildingOverviewEntry | undefined> {
    const building = this.ownedBuildings.value.find((b) => b.external_id == cadastralId);

    if (building !== undefined) {
      // update the building metadata by retrieving the latest info from the kadaster
      building.buildingMetadata = await this.generateBuildingMetadata(cadastralId);
    }

    // if no building was found, get the building using the kadaster api data
    if (building === undefined) return await this.createBuildingStub(cadastralId);
    return building;
  }

  async createBuildingStub(cadastralId: CadastralId): Promise<BuildingOverviewEntry> {
    const buildingMetadata = await this.generateBuildingMetadata(cadastralId as string);
    return { external_id: cadastralId, buildingMetadata } as BuildingOverviewEntry;
  }

  public async requestAddBuildingToOwnedBuildings(
    building: BuildingOverviewEntry
  ): Promise<BuildingOverviewEntry> {
    // Begin with creating a real estate api building
    const real_estate_building = await lastValueFrom(
      this.buildingsService.buildingsPost({
        external_id: building.external_id,
        coordinates: building.buildingMetadata?.location,
      })
    );
    const newOwnedBuilding = { ...building, real_estate_id: real_estate_building.id };

    // create all addresses
    (building.buildingMetadata?.addresses ?? []).map((item) => {
      this.addressableUnitsService
        .unitsPost({
          building_id: real_estate_building.id,
          address: item.address ?? '',
          zip_code: item.zip_code ?? '',
          place: item.place ?? '',
          house_number: item.house_number ?? '',
        })
        .subscribe();
    });

    // then create a dossier for this building
    const dossier = await this.dossierService.postDossier(real_estate_building.id);
    newOwnedBuilding.dossier_id = dossier.id;
    newOwnedBuilding.real_estate_id = real_estate_building.id;

    // update the buildings
    this.ownedBuildings.next([...this.ownedBuildings.value, newOwnedBuilding]);
    return newOwnedBuilding;
  }

  public getBuildingById(buildingId: string): Observable<BuildingOverviewEntry | undefined> {
    return this.ownedBuildings.pipe(
      map((buildings) => buildings.find((b) => b.real_estate_id === buildingId))
    );
  }

  public getLatLonFromCadastralId(cadastralId: CadastralId): Observable<LngLat> {
    const query = {
      identificatie: cadastralId,
      acceptCrs: 'epsg:28992',
    } as PandIdentificatieRequestParams;
    return this.pandService.pandIdentificatie(query).pipe(
      map((pandInfo: PandIOHal): LngLat => {
        // Calculates the coordinates to the average of the cadastral points.
        const coordinates = pandInfo.pand.geometrie.coordinates[0];
        const length = coordinates.length;
        const summedCoords = coordinates.reduce((acc: number[], coords: number[]) => [
          acc[0] + coords[0],
          acc[1] + coords[1],
        ]);
        const averageCoords = [summedCoords[0] / length, summedCoords[1] / length];
        const [lng, lat] = Proj4('EPSG:28992', 'EPSG:4326', [averageCoords[0], averageCoords[1]]);
        return new LngLat(lng, lat);
      })
    );
  }
}
