import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Product, ProductsResult, ProductsService } from '@api-clients/product';
import { ProductsAddPopupService, ProductsPopup } from './products-add-popup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContextPopupService } from '../context-popup/context-popup.service';
import { Context } from '@services/context.service';
import { Property } from '../model-viewer.component';

@Component({
  selector: 'app-products-add-popup',
  templateUrl: './products-add-popup.component.html',
  styleUrl: './products-add-popup.component.scss',
})
export class ProductsAddPopupComponent {
  @Input() initElementProperties?: Record<string, Property> = {};
  @Output() productsPopupChange = new EventEmitter();
  @ViewChild('input') input!: ElementRef;
  protected productsPopup: ProductsPopup;
  protected context: Context | undefined;
  protected activatedProducts: Product[] = [];
  protected alreadyAddedGtin: string[] = [];

  constructor(
    private readonly productService: ProductsService,
    public productsAddPopupService: ProductsAddPopupService,
    private readonly contextService: ContextPopupService
  ) {
    this.productsPopup = productsAddPopupService.productsPopup;
    productsAddPopupService.productsPopupChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.productsPopup = value;
    });
    //if contextPopup is closed, close this popup as well
    this.contextService.hidden.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closePopup();
    });
    this.productsAddPopupService.shown.pipe(takeUntilDestroyed()).subscribe(() => {
      this.input.nativeElement.focus();
    });
    this.productsAddPopupService.initialGtinProducts
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.alreadyAddedGtin = value;
      });
  }

  protected search: string = '';
  protected productsResult?: ProductsResult = undefined;
  protected previousOffset: number = 0;
  protected isBusy: boolean = false;

  doSearch(): void {
    this.previousOffset = 0;
    this.doRequest();
  }

  doRequest(): void {
    this.isBusy = true;
    this.productService.productsGet(this.search, this.previousOffset).subscribe((next) => {
      this.productsResult = next;
      this.isBusy = false;
    });
  }

  incrementOffset(): void {
    this.previousOffset += this.productsResult?.products.length ?? 0;
    this.doRequest();
  }

  toggleProduct(product: Product): void {
    if (!this.checkProduct(product)) {
      this.activatedProducts.push(product);
    } else {
      this.activatedProducts = this.activatedProducts.filter((item) => item !== product);
    }
  }

  closePopup(): void {
    this.productsAddPopupService.hide();

    //wait for hide then empty everything
    setTimeout(() => {
      this.productsResult = undefined;
      this.search = '';
      this.activatedProducts = [];
    }, 250);
  }

  addProducts(): void {
    this.closePopup();
    this.productsPopupChange.emit(this.activatedProducts);
  }

  checkProduct(product: Product): boolean {
    return (
      this.alreadyAddedGtin.findIndex((p) => p === product.gtin) >= 0 ||
      this.activatedProducts.findIndex((p) => product.gtin === p.gtin) >= 0
    );
  }

  keyDown(): void {}
}
