import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ContextPopupService } from './context-popup/context-popup.service';
import { ModelLoaderService } from '@shared/services/model-loader.service';
import { BuildingModel } from '@shared/services/assets/building-model';
import { DossierService } from '@services/dossier.service';
import { BimElementDto, DefaultService as BimApi, ObjectCategory } from '@api-clients/bim';
import { ShoppingCartService } from './shopping-cart.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModelViewerEditingService } from './model-viewer-editing-service';
import { filter, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ThreedComponent } from './threed/threed.component';
import { Product } from '@api-clients/product';

export interface Property {
  data_type: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  value?: string | number | boolean | Product[];
  unit?: string;
  values?: string[]; //used as source for the enum type
}

@Component({
  selector: 'app-model-viewer',
  templateUrl: './model-viewer.component.html',
  styleUrls: ['./model-viewer.component.scss'],
})
export class ModelViewerComponent implements OnInit {
  @Input() public fieldOfView = 30;
  buildingModel?: BuildingModel;
  selectedElement?: BimElementDto;
  context = this.contextPopupService.context;

  @ViewChild('threed') private threed!: ThreedComponent;
  private readonly building_id: string | null = '';
  private readonly bucketFileName: string | null = null;

  private modelId: string | undefined;
  private bimId: string | undefined;

  protected shoppingCartVisible: boolean = false;
  protected shoppingCartCount: number = 0;
  protected isSaving: boolean = false;

  protected breadcrumbTree: { name: string; link: string }[] = [];

  protected modelLoaded: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly contextPopupService: ContextPopupService,
    private readonly modelLoader: ModelLoaderService,
    private readonly bimApi: BimApi,
    private readonly dossierService: DossierService,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly router: Router,
    private readonly modelViewerEditingService: ModelViewerEditingService,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService
  ) {
    this.building_id = route.snapshot.paramMap.get('building_id');
    const modelId = route.snapshot.paramMap.get('model_id');
    if (modelId) this.modelId = modelId;
    else console.error('No model id provided');

    this.bucketFileName = route.snapshot.queryParamMap.get('file');

    this.context = contextPopupService.context;
    contextPopupService.contextChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.context = value;
    });
    this.shoppingCartService.content.pipe(takeUntilDestroyed()).subscribe((next) => {
      this.shoppingCartCount = next.flatMap((c) => c.changes).length;
    });

    this.shoppingCartService.active_element.pipe(takeUntilDestroyed()).subscribe((next) => {
      this.threed?.deselect();
      this.threed?.selectId(next);
    });

    this.contextPopupService.hidden.pipe(takeUntilDestroyed()).subscribe(() => {
      this.threed?.deselect();
    });

    this.modelViewerEditingService.addedToShoppingCart
      .pipe(takeUntilDestroyed())
      .subscribe((next) => {
        if (next > 0) {
          this.contextPopupService.cartAnimate();
        }

        this.contextPopupService.hide();
        this.threed?.deselect();
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).url)
      )
      .subscribe((next) => {
        this.shoppingCartVisible = next.substring(next.lastIndexOf('/') + 1) === 'changes';
        if (this.threed) this.threed.deselect();
        this.updateBreadCrumb();
      });

    this.shoppingCartService.content.pipe(takeUntilDestroyed()).subscribe((next) => {
      if (next.length === 0 && this.shoppingCartVisible) {
        void this.router.navigate(['details'], { relativeTo: this.route });
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.updateBreadCrumb();

    let buildingModel;
    if (this.bucketFileName) {
      buildingModel = (
        await this.modelLoader.loadModel(
          `https://storage.googleapis.com/tt-3d-models/${this.bucketFileName}`
        )
      )?.scene.children[0];
    } else if (this.building_id) {
      // Todo: Dossier should not be called separately here. Centralize Dossier calls in a service?
      const dossier = await this.dossierService.getDossier(this.building_id);
      const bimLinks = await this.dossierService.getBimLinksForDossier(dossier.id);
      this.bimId = bimLinks.at(0)?.linked_bim_id;
      if (!this.bimId) throw new Error('No bimId found for dossier.');
      buildingModel = await this.modelLoader.loadCompositeModelWithMetadata(this.bimId);
    } else throw new Error('No model to load. Both bimId and bucketFileName are undefined.');

    if (!buildingModel) throw new Error('Failed to load model.');
    this.buildingModel = buildingModel;

    this.threed.setModel(buildingModel);
    await this.threed.initRender();
    this.modelLoaded = true;
  }

  updateBreadCrumb(): void {
    this.breadcrumbTree = [];
    this.breadcrumbTree.push({
      name: this.translateService.instant('building_information'),
      link: '/buildings/' + this.route.snapshot.paramMap.get('building_id') + '/model/details',
    });
    if (this.shoppingCartVisible) {
      this.breadcrumbTree.push({
        name: this.translateService.instant('changes'),
        link: '/buildings/' + this.route.snapshot.paramMap.get('building_id') + '/model/changes',
      });
    }
  }

  toggleCategoryVisibility(categoryName: ObjectCategory): void {
    this.threed.needsUpdate();
    this.buildingModel?.toggleCategoryVisibility(categoryName);
  }

  toggleLevelVisibility(level: number): void {
    this.threed.needsUpdate();
    this.buildingModel?.toggleLevelVisibility(level);
  }

  protected meshClicked(id: string | undefined): void {
    if (id) {
      this.threed.selectId(id);
      if (
        !this.bimId ||
        id.length < 10 // Check for meshes with no matching bimId
      )
        return;

      this.bimApi
        .bimBimIdElementsElementIdGet(this.bimId, id)
        .subscribe(this.elementPropertiesPopup.bind(this));
    } else {
      this.selectedElement = undefined;
      this.contextPopupService.hide();
    }
  }

  private elementPropertiesPopup(element: BimElementDto): void {
    if (!this.bimId) return;
    this.selectedElement = element;
    const propertyDefinition = this.modelViewerEditingService.popupElement(this.bimId, element);
    if (!propertyDefinition) return;

    this.contextPopupService.show();
  }

  async showShoppingCart(): Promise<void> {
    await this.router.navigate(['changes'], { relativeTo: this.route });
  }

  async saveShoppingCart(): Promise<void> {
    if (this.isSaving) return;

    this.isSaving = true;

    await this.modelViewerEditingService.saveShoppingCart();

    this.toastr.success(this.translateService.instant('changes_saved'));

    this.isSaving = false;

    await this.router.navigate(['details'], { relativeTo: this.route });
  }
}
