import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-workspace-status-bubble',
  templateUrl: './status-bubble.component.html',
  styleUrls: ['./status-bubble.component.scss'],
})
export class WorkspaceStatusBubbleComponent {
  @Input() status = '';
}
