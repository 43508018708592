import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DemoRoutingModule } from './demo-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { SharedModule } from '@shared/shared.module';
import { ViewerComponent } from './components/viewer/viewer.component';
import { Map2DComponent } from './components/viewer/map/map-2d/map-2d.component';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { LegendComponent } from './components/viewer/map/map-2d/legend/legend.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    LandingPageComponent,
    ListViewComponent,
    ViewerComponent,
    Map2DComponent,
    LegendComponent,
  ],
  imports: [
    CommonModule,
    DemoRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    MatCheckboxModule,
  ],
  exports: [],
})
export class DemoModule {}
