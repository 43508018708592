import { Injectable } from '@angular/core';
import {
  WorkspaceCreationRequest,
  WorkspaceService as WorkspaceApi,
  WorkspaceUpdateRequest,
  SectionItemsService,
  AddSectionRequest,
  AddQuestionRequest,
  FileRequestItem,
  UpdateFileRequest,
  UpdateQuestionRequest,
  UpdateSectionRequest,
  WorkspaceDto,
} from '@api-clients/workspace';
import { ContentService as S3 } from '@api-clients/s3';
import { lastValueFrom, mergeMap } from 'rxjs';
import { RequestContentItem, Question, Section, Workspace } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private _workspaces: Workspace[] = [];
  private _templates: Workspace[] = [];

  constructor(
    private workspaceApi: WorkspaceApi,
    private sectionItemsService: SectionItemsService,
    private s3Service: S3
  ) {
    void this.listWorkspaces(false, false);
    void this.listWorkspaces(true, false);
  }

  async listWorkspaces(templates = false, deep = true): Promise<Workspace[]> {
    const result = await lastValueFrom(this.workspaceApi.v1WorkspacesGet(templates, 'body', false))
      .then((workspaces) => workspaces.map((ws) => new Workspace(ws, this)))
      .then((workspaces) => {
        if (deep) {
          // this.shareInfoService.GetShareInformation(...workspaces);
          // this.getFileRequests(workspaces);
        }
        return workspaces;
      });

    if (templates) this._templates = result;
    else this._workspaces = result;
    return result;
  }

  get workspaces(): Workspace[] {
    return this._workspaces;
  }

  get templates(): Workspace[] {
    return this._templates;
  }

  // addTemplate(
  //   workspaceId: string,
  //   sectionId: string,
  //   templateId: string,
  //   insertAt?: number
  // ): Promise<Workspace> {
  //   return this.http
  //     .post(`${this.baseUrl}/${workspaceId}/sections/${sectionId}/template/${templateId}`, {
  //       insertAt,
  //     })
  //     .then((response) => new Workspace(response.body))
  //     .then((ws) => this.updateReferences(templateId, ws));
  // }

  getWorkspace(workspaceId: string): Promise<Workspace> {
    return lastValueFrom(this.workspaceApi.getWorkspaceById(workspaceId)).then(
      (ws) => new Workspace(ws, this)
    );
  }

  createWorkspace(request: WorkspaceCreationRequest): Promise<WorkspaceDto> {
    return lastValueFrom(this.workspaceApi.v1WorkspacesPost(request, 'body'));
  }

  updateWorkspace(workspaceId: string, request: WorkspaceUpdateRequest): Promise<string> {
    return lastValueFrom(this.workspaceApi.v1WorkspacesIdPut(workspaceId, request, 'body'));
  }

  async deleteWorkspace(workspaceId: string): Promise<void> {
    await Promise.all([
      lastValueFrom(
        this.s3Service.workspaceWorkspaceIdDelete(workspaceId, { deleteWorkspace: true })
      ),
      lastValueFrom(this.workspaceApi.v1WorkspacesWorkspaceIdDelete(workspaceId)),
    ]);
  }
  //
  // async updateReferences(sourceId: string, destination: Workspace): Promise<Workspace> {
  //   if (!sourceId) return destination;
  //   const source = await this.get(sourceId);
  //   return this.workspaceCopier.updateReferences(source, destination);
  // }

  // async getFileRequests(workspaces: Workspace[]): Promise<void> {
  //   const response = await this.http.post<WorkspaceWithFrs[]>(
  //     `${this.baseUrl}/fileRequests`,
  //     workspaces.map((ws) => ws.id)
  //   );
  //   const workspacesWithFrs = response.body;
  //   workspacesWithFrs.forEach((wsWithFrs) => {
  //     const workspace = workspaces.find((ws) => ws.id === wsWithFrs.id);
  //     workspace.allRcis = wsWithFrs.requestContentItems.map((rci) => new FileRequest(rci));
  //   });
  // }

  refresh(): Promise<Workspace[]> {
    return this.listWorkspaces(true, false);
  }

  async updateSection(
    workspaceId: string,
    sectionId: string,
    request: UpdateSectionRequest
  ): Promise<void> {
    const succesful = await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdPut(
        workspaceId,
        sectionId,
        request
      )
    );
    if (!succesful) throw new Error('Failed to update section');
  }

  async updateQuestion(
    workspaceId: string,
    sectionId: string,
    questionId: string,
    request: UpdateQuestionRequest
  ): Promise<void> {
    const succesful = await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdQuestionsQuestionIdPut(
        workspaceId,
        sectionId,
        questionId,
        request
      )
    );
    if (!succesful) throw new Error('Failed to update question');
  }

  async updateFileRequest(
    workspaceId: string,
    sectionId: string,
    fileRequestId: string,
    request: UpdateFileRequest
  ): Promise<void> {
    const succesful = await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdFilerequestsFileRequestIdPut(
        workspaceId,
        sectionId,
        fileRequestId,
        request
      )
    );
    if (!succesful) throw new Error('Failed to update file request');
  }

  async addSection(
    workspaceId: string,
    parentSectionId: string,
    addSectionRequest: AddSectionRequest
  ): Promise<Section> {
    return await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdSectionsPost(
        workspaceId,
        parentSectionId,
        addSectionRequest
      )
    ).then((sectionItem) => new Section(sectionItem, this));
  }

  async addQuestion(
    workspaceId: string,
    parentSectionId: string,
    addQuestionRequest: AddQuestionRequest
  ): Promise<Question> {
    return await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdQuestionsPost(
        workspaceId,
        parentSectionId,
        addQuestionRequest
      )
    ).then((questionItem) => new Question(questionItem, this));
  }

  async addFileRequest(
    workspaceId: string,
    parentSectionId: string,
    fileRequestItem: FileRequestItem
  ): Promise<RequestContentItem> {
    return await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdFilerequestsPost(
        workspaceId,
        parentSectionId,
        fileRequestItem
      )
    ).then((questionItem) => new RequestContentItem(questionItem, this));
  }

  async deleteSection(workspaceId: string, sectionId: string): Promise<void> {
    await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdDelete(
        workspaceId,
        sectionId
      )
    );
  }

  async deleteQuestion(workspaceId: string, sectionId: string, questionId: string): Promise<void> {
    await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdQuestionsQuestionIdDelete(
        workspaceId,
        sectionId,
        questionId
      )
    );
  }

  async deleteFileRequest(
    workspaceId: string,
    sectionId: string,
    fileRequestId: string
  ): Promise<void> {
    await lastValueFrom(
      this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdFilerequestsFileRequestIdDelete(
        workspaceId,
        sectionId,
        fileRequestId
      )
    );
  }

  async addFileToFileRequest(
    workspaceId: string,
    sectionId: string,
    fileRequestId: string,
    file: File
  ): Promise<void> /* TODO: Shouldn't we get a content-item in return? */ {
    const upload = this.s3Service.uploadPost(file, file.name, workspaceId).pipe(
      mergeMap((returned) => {
        return this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdFilerequestsFileRequestIdContentItemsContentItemIdPost(
          workspaceId,
          sectionId,
          fileRequestId,
          returned.id!
        );
      })
    );

    return await lastValueFrom(upload);
  }

  async deleteFileFromFileRequest(
    workspaceId: string,
    sectionId: string,
    fileRequestId: string,
    contentItemId
  ): Promise<void> {
    const response = this.s3Service
      .workspaceWorkspaceIdDelete(workspaceId, { contentItemIds: [contentItemId] })
      .pipe(
        mergeMap(() =>
          this.sectionItemsService.v1WorkspacesWorkspaceIdSectionsSectionIdFilerequestsFileRequestIdContentItemsContentItemIdDelete(
            workspaceId,
            sectionId,
            fileRequestId,
            contentItemId
          )
        )
      );
    await lastValueFrom(response);
  }
}
