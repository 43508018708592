import {
  InspectionValue,
  InspectionValueValueBoolean,
  InspectionValueValueImages,
  InspectionValueValueNumber,
  InspectionValueValueText,
} from '@api-clients/dossier';

export function isImagesAnswer(answer: InspectionValue): answer is InspectionValueValueImages {
  return answer.type === InspectionValueValueImages.TypeEnum.ImageList;
}

export function isTextAnswer(answer: InspectionValue): answer is InspectionValueValueText {
  return answer.type === InspectionValueValueText.TypeEnum.Text;
}

export function isNumberAnswer(answer: InspectionValue): answer is InspectionValueValueNumber {
  return answer.type === InspectionValueValueNumber.TypeEnum.Number;
}

export function isBooleanAnswer(answer: InspectionValue): answer is InspectionValueValueBoolean {
  return answer.type === InspectionValueValueBoolean.TypeEnum.Boolean;
}
