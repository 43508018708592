import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { DossierDetailComponent } from './views/dossier-detail/dossier-detail.component';
import { InspectionTemplatesComponent } from './views/inspection-templates/inspection-templates.component';
import { InspectionTemplateDetailComponent } from './views/inspection-template-detail/inspection-template-detail.component';

const routes: Routes = [
  {
    path: 'buildings/:id/dossier',
    component: DossierDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'inspection_templates',
    component: InspectionTemplatesComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'inspection_templates/edit/:id',
    component: InspectionTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'inspection_templates/new',
    component: InspectionTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DossiersRoutingModule {}
