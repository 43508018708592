<div class="inspection-result" *ngIf="inspection">
  <div class="inspection-result__intro">
    <h2>{{ inspection.content.inspection_name }}</h2>
    <span *ngIf="user">{{ 'filled-on-inspection' | translate }} {{ user }}</span>
    <span *ngIf="timeStamp">
      {{ 'on-date-inspection' | translate }} {{ timeStamp | date: 'dd-MM-yyyy' }}
      {{ 'at-time-inspection' | translate }} {{ timeStamp | date: 'HH:mm' }}
    </span>
  </div>

  <div
    class="inspection-result__section"
    *ngFor="let section of inspection.content.sections">
    <h3>{{section.name}}</h3>
    <div
      class="inspection-result-section-question"
      *ngFor="let finding of section.findings">
      {{finding.subject.title}}

      <div
        class="inspection-result__section__question__images"
        *ngIf="finding.value !== undefined && isImagesAnswer(finding.value)">
        <div
          class="image"
          tabindex="0"
          (keydown)="keyDown($event)"
          *ngFor="let img_id of finding.value.image_ids"
          (click)="openImage(item, img_id)">
          <img
            [src]="
              environment.API_BASE_PATH +
                '/dossiers/dossiers/' +
                item.dossier_id +
                '/image/' +
                img_id
                | secure
                | async
            "
            alt="image" />
          <span class="material-symbols-rounded">zoom_in</span>
        </div>
      </div>
      <div class="inspection-result-section-question-text" *ngIf="finding.value !== undefined && isTextAnswer(finding.value)">
        {{ finding.value.text }}
      </div>
      <div class="inspection-result-section-question-number" *ngIf="finding.value !== undefined && isNumberAnswer(finding.value)">
        {{ finding.value.number }}
      </div>
      <div class="inspection-result-section-question-boolean" *ngIf="finding.value !== undefined && isBooleanAnswer(finding.value)">
        <input type="checkbox" [checked]="finding.value.boolean">
      </div>
    </div>
  </div>

  <div
    class="inspection-result-image-popup"
    [class.active]="selectedFile"
    (click)="deselectFile()"
    tabindex="0"
    (keydown)="keyDown($event)">
    <div
      class="inspection-result-image-popup__content"
      (click)="$event.stopPropagation()"
      tabindex="0"
      (keydown)="keyDown($event)">
      <app-file-specs
        [timeStamp]="item.timestamp_utc"
        [user]="item.userName"
        [fileUrl]="selectedFileUrl"
        [file]="selectedFile"></app-file-specs>
    </div>
  </div>
</div>
