import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SectionItem } from '../../models';
import { QuestionType } from '@api-clients/workspace';

@Component({
  selector: 'app-section-item',
  templateUrl: './section-item.component.html',
  styleUrl: './section-item.component.scss',
})
export class SectionItemComponent {
  readonly QuestionType = QuestionType;

  @Input() item!: SectionItem;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() deleteChild = new EventEmitter<SectionItem>();
  @Output() move = new EventEmitter<number>();

  constructor() {}
}
