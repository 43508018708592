<!--<div *ngIf="buildingMetadata | async as metadata" class="specs">-->
<!--  <h3 class="h5">{{ metadata.address }}</h3>-->
<!--  <h4 class="address">{{ metadata.postalCode }}</h4>-->
<!--  <h4 class="address">{{ metadata.city }}</h4>-->
<!--</div>-->

<div [class.active]="context.active" class="context-wrapper">
  <app-information-container>
    <app-information-description title="Appartementen">
      <p>Omschrijving over iets of inputs.</p>
    </app-information-description>

    <app-information-item [open]="true" title="Categories">
      <div *ngFor="let category of categories">
        <span class="property">{{ category.type | lowercase | translate }}</span>
        <button
          (click)="toggleCategory(category.type)"
          [class.active]="!category.visible"
          class="material-symbols-rounded eye-button">
          visibility
        </button>
      </div>
    </app-information-item>

    <app-information-item *ngIf="levels" [open]="true" title="Levels">
      <div *ngFor="let level of levels">
        <span class="property">{{ level.name }}</span>
        <button
          (click)="toggleLevel(level.level)"
          [class.active]="!level.visible"
          class="material-symbols-rounded eye-button">
          visibility
        </button>
      </div>
    </app-information-item>
  </app-information-container>
</div>
