<div class="toolbox" [class.active]="edit">
  <div class="toolbox__wrapper">
    <button class="material-symbols-rounded toolbox-function" (click)="insertSection(false)">
      inbox
    </button>
    <button class="material-symbols-rounded toolbox-function" (click)="insertSection(true)">
      folder
    </button>
    <button class="material-symbols-rounded toolbox-function" (click)="functionClick()">
      source_notes
    </button>

    <div class="divider"></div>

    <button class="material-symbols-rounded toolbox-function" (click)="insertFileUpload()">
      attach_file
    </button>
    <button class="material-symbols-rounded toolbox-function" (click)="insertRadioList()">
      radio_button_checked
    </button>
    <button class="material-symbols-rounded toolbox-function" (click)="insertCheckList()">
      check_box
    </button>
    <button class="material-symbols-rounded toolbox-function" (click)="insertOpenQuestion()">
      edit_square
    </button>
  </div>
</div>
