import { EventEmitter, Injectable } from '@angular/core';
import { Property } from './model-viewer.component';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  BimElement,
  BimElementDto,
  DefaultService as BimApi,
  ObjectCategory,
  RoomCategory,
} from '@api-clients/bim';
import { isBimObject, isBimRoom } from './utils/types';
import propertyDefinitions from './properties.json';
import { CategoryChange, Change, ChangedElement } from './changes-summary/change';
import { ShoppingCartService } from './shopping-cart.service';
import { Product } from '@api-clients/product';

@Injectable({
  providedIn: 'root',
})
export class ModelViewerEditingService {
  private bimId?: string;
  private initialCategory: string | undefined;
  private initialProperties?: Record<string, Property> = {}; //contains the original values
  private selectedElement?: BimElementDto;

  public elementProperties: BehaviorSubject<Record<string, Property> | undefined> =
    new BehaviorSubject<Record<string, Property> | undefined>({});

  public element: BehaviorSubject<BimElement> = new BehaviorSubject<BimElement>({} as BimElement);
  public addedToShoppingCart: EventEmitter<number> = new EventEmitter<number>();

  /*  public saveClicked: EventEmitter<Record<string, Property>> = new EventEmitter<
    Record<string, Property>
  >();*/

  constructor(
    private readonly shoppingCartService: ShoppingCartService,
    private readonly bimApi: BimApi
  ) {}

  public popupElement(
    bimId: string,
    elementDto: BimElementDto
  ): Record<string, Property> | undefined {
    const element = elementDto.element;
    this.bimId = bimId;
    this.selectedElement = elementDto;
    if (!isBimObject(element) && !isBimRoom(element)) return;
    let category = element.category.toString();
    this.initialCategory = category;
    if (isBimRoom(element)) category = 'Room';
    const propertyDefinition: Record<string, Property> | undefined =
      propertyDefinitions[category]?.properties;
    if (!propertyDefinition) return;
    // Go over the defined property list. If we received a value from backend: fill it in.
    for (const [key, value] of Object.entries(propertyDefinition)) {
      value.value = element.properties[key];
      if (key === 'products' && value.value === undefined) {
        value.value = [];
      }
    }
    this.initialProperties = JSON.parse(JSON.stringify(propertyDefinition));
    this.elementProperties.next(propertyDefinition);
    this.element.next(element);

    return propertyDefinition;
  }

  public async addPropertiesToShoppingCart(
    selectedProperties: Record<string, Property>
  ): Promise<void> {
    const bimId = this.bimId;
    if (!this.selectedElement || !selectedProperties || !bimId || !this.initialProperties) return;

    const changes: (Change | CategoryChange)[] = [];

    for (const [key, value] of Object.entries(selectedProperties)) {
      if (value.value === undefined) continue;
      const initialValue = this.initialProperties[key]?.value;
      if (!this.deepEqual(initialValue, value.value)) {
        changes.push(new Change(key, initialValue, value.value));
      }
    }

    if (isBimRoom(this.selectedElement.element) || isBimObject(this.selectedElement.element)) {
      const initialValue = this.initialCategory;
      if (initialValue !== this.selectedElement.element.category) {
        changes.push(new CategoryChange(initialValue, this.selectedElement.element.category));
      }
    }

    const changedElement: ChangedElement = new ChangedElement(
      this.selectedElement.id,
      this.selectedElement,
      changes
    );

    this.shoppingCartService.update(changedElement);
    this.addedToShoppingCart.emit(changes.length);
  }

  private deepEqual(x, y): boolean {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === 'object' && tx === ty
      ? ok(x).length === ok(y).length && ok(x).every((key) => this.deepEqual(x[key], y[key]))
      : x === y;
  }

  public addProducts(productsToAdd: []): void {
    const elementProperties = this.elementProperties?.value;

    if (elementProperties!['products'] === undefined) {
      elementProperties!['products'] = {
        data_type: 'products',
        value: productsToAdd,
      };
    } else {
      elementProperties!['products'] = {
        data_type: 'products',
        value: [...(<Product[]>(elementProperties!['products']?.value || [])), ...productsToAdd],
      };
    }
    this.elementProperties.next(elementProperties);
  }

  public async saveShoppingCart(): Promise<void> {
    const bimId = this.bimId;
    if (!bimId) return;

    for (const changedElement of this.shoppingCartService.content.value) {
      const bimElement = await lastValueFrom(
        this.bimApi.bimBimIdElementsElementIdGet(bimId, changedElement.id)
      );

      for (const change of changedElement.changes) {
        if (change instanceof Change) {
          bimElement.element.properties[change.propertyName] = change.newValue;
        }
        if (change instanceof CategoryChange && isBimRoom(bimElement.element)) {
          bimElement.element.category = RoomCategory[change.newValue ?? 'Undefined'];
        }
        if (change instanceof CategoryChange && isBimObject(bimElement.element)) {
          bimElement.element.category = ObjectCategory[change.newValue ?? 'Undefined'];
        }
      }

      await lastValueFrom(
        this.bimApi.bimBimIdElementsElementIdPut(bimId, changedElement.id, bimElement.element)
      );
    }

    this.shoppingCartService.clear();
  }
}
