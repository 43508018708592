<div class="model-viewer">
  <div class="big-spinner" *ngIf="!modelLoaded || this.isSaving"></div>

  <app-threed #threed [fieldOfView]="fieldOfView" (meshClicked)="meshClicked($event)">
    <div class="sidebar" *ngIf="modelLoaded">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <div
        class="sidebar__content"
        [class.hide]="context.active"
        *ngIf="!shoppingCartVisible && buildingModel">
        <app-residence-sidebar
          (toggleCategoryVisibility)="toggleCategoryVisibility($event)"
          [categories]="buildingModel.categories"
          (toggleLevelVisibility)="toggleLevelVisibility($event)"
          [levels]="buildingModel.levels" />
      </div>

      <router-outlet></router-outlet>
    </div>

    <button
      class="button-primary cta-button"
      *ngIf="!shoppingCartVisible"
      [disabled]="shoppingCartCount === 0"
      [class.animate]="context.animate"
      (click)="showShoppingCart()">
      ({{ shoppingCartCount }}) {{ 'changes' | translate }}
    </button>
    <button
      class="button-primary cta-button"
      *ngIf="shoppingCartVisible"
      [disabled]="shoppingCartCount === 0 || isSaving"
      (click)="saveShoppingCart()">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'save' | translate }}
    </button>
  </app-threed>
</div>
