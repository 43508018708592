import { EventEmitter, Injectable } from '@angular/core';
import { ChangedElement, Change, CategoryChange } from './changes-summary/change';
import { BehaviorSubject } from 'rxjs';
import { ActivationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  public content: BehaviorSubject<ChangedElement[]> = new BehaviorSubject<ChangedElement[]>([]);
  public active_element: EventEmitter<string> = new EventEmitter<string>();
  public removed_change: EventEmitter<Change | CategoryChange> = new EventEmitter<
    Change | CategoryChange
  >();

  public building_id: string | null = null;

  constructor(private readonly router: Router) {
    router.events.subscribe((e) => {
      // fixes the issue in that shopping cart is not cleared when navigating to a different building
      if (e instanceof ActivationEnd) {
        const building_id = e.snapshot.params['building_id'];
        if (building_id !== undefined && building_id !== this.building_id) {
          this.clear();
          this.building_id = building_id;
        }
      }
    });
  }

  // Adds or updates an element with it's changed properties to the shopping cart
  public update(changedElement: ChangedElement): void {
    const currentChanges = this.content.value;

    const existing = currentChanges.find((e) => e.id == changedElement.id);
    if (existing !== undefined) {
      existing.changes = changedElement.changes;
    } else {
      this.content.value.push(changedElement);
    }
    this.cleanUpEmptyElements(changedElement);
    this.content.next(currentChanges);
  }

  public remove(changedElement: ChangedElement, change: Change | CategoryChange): void {
    const currentChanges = this.content.value;

    const index = changedElement.changes.findIndex((c) => c === change);
    changedElement.changes.splice(index, 1);

    this.cleanUpEmptyElements(changedElement);

    this.removed_change.emit(change);
    this.content.next(currentChanges);
  }

  private cleanUpEmptyElements(changedElement: ChangedElement): void {
    if (changedElement.changes.length === 0) {
      const index = this.content.value.findIndex((c) => c === changedElement);
      this.content.value.splice(index, 1);
    }
  }

  public clear(): void {
    this.content.next([]);
  }

  public activateElement(element_id: string): void {
    this.active_element.next(element_id);
  }
}
