<svg #svg [attr.viewBox]="viewBox" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" (click)="unselect()" (mousedown)="clickDisabled = false">
  <defs>
    <marker
      id="arrow"
      viewBox="0 0 15 15"
      refX="14"
      refY="7.5"
      markerWidth="9"
      markerHeight="9"
      orient="auto-start-reverse">
      <path d="M 0 0 L 15 7.5 L 0 15 z" />
    </marker>
  </defs>

  <g *ngIf="currentLevel">
    <g class="rooms">
      <g class="room" *ngFor="let room of currentLevel.rooms" (click)="selectRoom(room); $event.stopPropagation();">
        <polygon [attr.points]="room.points" fill="#e0dec0" fill-opacity="1" stroke-width="10" stroke="#000000"></polygon>
        <polygon [attr.points]="room.points" fill="#ff000080" fill-opacity="1" stroke-width="0" *ngIf="selectedElement === room"></polygon>
      </g>
      <g class="room-texts" *ngFor="let room of currentLevel.rooms">
        <text
          font-size="100pt"
          [attr.x]="room.bbox.x1 + ((room.bbox.x2 - room.bbox.x1) / 2)"
          [attr.y]="room.bbox.y1 + ((room.bbox.y2 - room.bbox.y1) / 2)" [attr.width]="room.bbox.x2 - room.bbox.x1"
          dominant-baseline="middle" text-anchor="middle">{{ room.category | translate }}
        </text>
      </g>
    </g>

    <g class="walls">
      <g class="wall" *ngFor="let wall of currentLevel.walls" (click)="selectWall(wall); $event.stopPropagation();">
        <polygon [attr.points]="wall.points" fill="#000000"></polygon>
        <polygon [attr.points]="wall.points" fill="#ff000080" *ngIf="selectedElement === wall"></polygon>
      </g>
    </g>

    <g class="doors">
      <g class="door" *ngFor="let door of currentLevel.doors" (click)="selectDoor(door); $event.stopPropagation();"
         [attr.style]="'transform: translateX(' + door.bbox.x1 + 'px) translateY(' + door.bbox.y1 + 'px)'">
        <g [attr.style]="'transform-box: fill-box; transform: rotate('+door.rotate+'deg); transform-origin: center;'">
          <rect [attr.x]="0" [attr.y]="0" [attr.width]="door.width" [attr.height]="door.height" fill="#d2d2d2"></rect>
          <rect [attr.x]="0" [attr.y]="0" [attr.width]="door.width" [attr.height]="door.height" fill="#ff000080" *ngIf="selectedElement === door"></rect>
<!--
          <g class="door-horizontal" *ngIf="!door.vertical">
            &lt;!&ndash; upper opened to left &ndash;&gt;
            <path fill="none" stroke="#aaa" stroke-width="0.01" [attr.d]="'M0 0 L'+(door.width - 0.10)+' -0.2'"></path>
            <path fill="none" stroke="#aaa" stroke-width="0.01" [attr.d]="'M '+(door.width - 0.10) +' 0 C '+(door.width -0.09)+' 0.02 '+(door.width - 0.04)+' 0.05 '+door.width+' 0.2'" style="transform: translateY(-0.2px)"></path>

            &lt;!&ndash; upper opened to right &ndash;&gt;
            <path fill="none" stroke="#aaa" stroke-width="0.01" [attr.d]="'M0.1 -0.2 L'+door.width+' 0'"></path>
            <path fill="none" stroke="#aaa" stroke-width="0.01" [attr.d]="'M '+(door.width - 0.10) +' 0 C '+(door.width -0.09)+' 0.02 '+(door.width - 0.04)+' 0.05 '+door.width+' 0.2'" style="transform: translateY(-0.2px)"></path>

            &lt;!&ndash; lower opened to left &ndash;&gt;
            <path fill="none" stroke="#aaa" stroke-width="0.01" [attr.d]="'M0 '+door.height+' L'+(door.width - 0.10)+' '+door.height+0.2"></path>
&lt;!&ndash;
            <path fill="none" stroke="#aaa" stroke-width="0.01" [attr.d]="'M '+(door.width - 0.10) +' 0 C '+(door.width -0.09)+' 0.02 '+(door.width - 0.04)+' 0.05 '+door.width+' 0.2'" style="transform: translateY(-0.2px)"></path>
&ndash;&gt;
          </g>
-->
<!--
          <g class="door-vertical" *ngIf="door.vertical">
            <path fill="none" stroke="#000" stroke-width="0.01" [attr.d]="'M -0.0020 0.0010 L 0.001 '+door.height"></path>
          </g>
-->
        </g>
      </g>
    </g>

    <g class="windows">
      <g class="window" *ngFor="let window of currentLevel.windows" (click)="selectWindow(window); $event.stopPropagation();">
        <polygon [attr.points]="window.points" fill="#4fb0e2"></polygon>
        <polygon [attr.points]="window.points" fill="#ff000080" *ngIf="selectedElement === window"></polygon>

        <line *ngIf="window.rotate === 0" [attr.x1]="window.bbox.x1"
              [attr.y1]="window.bbox.y1 + (window.height / 2)" [attr.x2]="window.bbox.x2"
              [attr.y2]="window.bbox.y1 + (window.height / 2)" stroke="black"
              stroke-width="10"></line>
        <line *ngIf="window.rotate === -90" [attr.x1]="window.bbox.x1 + (window.width / 2)"
              [attr.y1]="window.bbox.y1" [attr.x2]="window.bbox.x1 + (window.width / 2)"
              [attr.y2]="window.bbox.y2" stroke="black" stroke-width="10"></line>
      </g>
    </g>

    <g class="dimensions-width" [attr.style]="'transform: translateY(' + (floorPlan.boundingBox.y2 + 100) + 'px)'">
      <g *ngFor="let grp of currentLevel.dimensions.widths; let i = index">
        <g class="dimension" *ngFor="let d of grp">
          <line marker-end='url(#arrow)' marker-start='url(#arrow)' stroke-width="10" fill='none' stroke='black' [attr.x1]="d.p1" [attr.y1]="i*200" [attr.x2]="d.p2" [attr.y2]="i*200"></line>
          <text [attr.x]="d.p1 + (d.length / 2)" [attr.y]="(i * 200)+100" dominant-baseline="middle" text-anchor="middle" font-size="100pt">{{ d.length / 1000 | number:'0.2-2' }}m</text>
        </g>
      </g>
    </g>

    <g class="dimensions-height" [attr.style]="'transform: translateX(' + (floorPlan.boundingBox.x2 + 100) + 'px)'">
      <g *ngFor="let grp of currentLevel.dimensions.heights; let i = index">
        <g class="dimension" *ngFor="let d of grp">
          <line marker-end='url(#arrow)' marker-start='url(#arrow)' stroke-width="10" fill='none' stroke='black' [attr.x1]="i*200" [attr.y1]="d.p1" [attr.x2]="i*200" [attr.y2]="d.p2"></line>
          <text [attr.x]="(i * 200)+100" [attr.y]="d.p1 + (d.length / 2)" dominant-baseline="middle" text-anchor="middle" font-size="100pt" style="transform: rotate(270deg); transform-box: fill-box; transform-origin: center;" >{{ d.length / 1000 | number:'0.2-2' }}m</text>
        </g>
      </g>
    </g>

  </g>
</svg>
<!--
<pre>{{ currentLevel.dimensions | json }}</pre>
-->
