import { LngLat } from 'maplibre-gl';
import { AdresIOHal, PandIOHal, VerblijfsobjectIOHal } from '@api-clients/kadaster';
import Proj4 from 'proj4';
import { AddressableUnitDto } from '@api-clients/real-estate';

export interface OnlineModelMetadata {
  id: string;
  full?: string;
}

export class AddressMetadata {
  address?: string;
  house_number?: string;
  postalCode?: string;
  place?: string;
}

export class BuildingMetadata {
  name?: string;
  address?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  additionalFiles?: string[];
  model?: OnlineModelMetadata | null;
  location?: LngLat;
  rotation?: number;
  yearOfConstruction?: string;
  status?: string;
  addresses: AddressableUnitDto[] = [];
  surface?: number;
  usages?: string[];

  constructor(
    address?: AdresIOHal[],
    pandInfo?: PandIOHal,
    verblijfsObjectInfo?: VerblijfsobjectIOHal
  ) {
    if (address) setAddresses(this, address);
    if (pandInfo) setPandInfo(this, pandInfo);
    if (verblijfsObjectInfo) setVerblijfsObjectInfo(this, verblijfsObjectInfo);
  }
}

export const setAddresses = (metadata: BuildingMetadata, addresses: AdresIOHal[]): void => {
  const address = addresses[0];
  metadata.address = address.adresregel5;
  metadata.postalCode = address.postcode;
  metadata.city = address.woonplaatsNaam;

  addresses.forEach(function (item): void {
    metadata.addresses.push({
      id: '', // not available for addresses retrieved from kadaster
      address: item.openbareRuimteNaam,
      place: item.woonplaatsNaam,
      house_number: item.adresregel5?.substring(item.openbareRuimteNaam.length + 1) ?? '',
      zip_code: item.postcode ?? '',
    });
  });
};

export const setPandInfo = (metadata: BuildingMetadata, pandInfo: PandIOHal): void => {
  const coord = (pandInfo.pand.geometrie.coordinates[0] || [])[0];
  if (coord) {
    const transformed = Proj4('EPSG:28992', 'EPSG:4326', [coord[0], coord[1]]);
    const latLon = new LngLat(transformed[0], transformed[1]);
    metadata.location = latLon;
    // It is important that rotation is initialized to non 0, otherwise the model will not be visible
    metadata.rotation = Math.PI * 2;
  }
  metadata.yearOfConstruction = pandInfo.pand.oorspronkelijkBouwjaar;
  metadata.status = pandInfo.pand.status;
};

export const setVerblijfsObjectInfo = (
  metadata: BuildingMetadata,
  verblijfsObject: VerblijfsobjectIOHal
): void => {
  metadata.surface = verblijfsObject.verblijfsobject.oppervlakte;
  metadata.usages = verblijfsObject.verblijfsobject.gebruiksdoelen.map((s) => s.toString());
};
