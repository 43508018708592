<div class="products-add-popup" [class.active]="productsPopup.active">
  <div class="products-add-popup__header">
    <h5>{{ 'add-products' | translate }}</h5>

    <button (click)="closePopup()" (keydown)="closePopup()" class="material-symbols-rounded">
      close
    </button>
  </div>
  <div class="products-add-popup__content">
    <form class="search-input" (submit)="doSearch()">
      <input
        #input
        type="text"
        placeholder="{{ 'search' | translate }}"
        id="search"
        [(ngModel)]="search"
        [ngModelOptions]="{ standalone: true }" />
      <button
        type="submit"
        class="send-button"
        [disabled]="isBusy"
        [class.visible]="input.value.length > 0">
        <span class="material-symbols-rounded">search</span>
      </button>
    </form>

    <div
      class="products-add-popup__content__results__header"
      *ngIf="productsResult !== undefined && productsResult.products.length">
      <span class="description">{{ 'article-name' | translate }}</span>
      <span class="id">{{ 'gtin' | translate }}</span>
    </div>
    <ul
      class="products-add-popup__content__results"
      *ngIf="productsResult !== undefined && productsResult.products.length">
      <li
        *ngFor="let product of productsResult.products"
        (click)="toggleProduct(product)"
        (keydown)="keyDown()"
        tabindex="0"
        [class.added]="checkProduct(product)">
        <div class="checkbox"></div>
        <img [src]="product.cdn" [alt]="product.gtin" />
        <span class="description">
          {{ product.description }}
        </span>

        <span class="id">
          {{ product.gtin }}
        </span>
      </li>
    </ul>

    <div
      class="no-result"
      *ngIf="productsResult !== undefined && productsResult.products.length === 0">
      {{ 'no-result-product-search' | translate }}
    </div>

    <button
      (click)="incrementOffset()"
      class="next-page"
      [disabled]="isBusy"
      *ngIf="(productsResult?.total_count ?? 0) > 10">
      {{ 'next-page' | translate }}
    </button>

    <button
      (click)="addProducts()"
      class="button-primary"
      [disabled]="isBusy || activatedProducts.length === 0">
      ({{ activatedProducts.length }})
      {{ 'add' | translate }}
    </button>
  </div>
</div>
<div class="products-add-popup__background" [class.active]="productsPopup.active"></div>
