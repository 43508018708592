import { getBBox, toSvgPoints } from '@shared/components/floor-plan/utils';
import { RoomCategory } from '@api-clients/bim';

export class Room {
  public readonly polygons: { x: number; y: number }[];
  public category: string;
  public readonly bbox: { x1: number; y1: number; x2: number; y2: number };
  public readonly points: string;
  public readonly bimId: string;
  public readonly width: number;
  public readonly height: number;

  constructor(polygons: { x: number; y: number }[], category: RoomCategory, bimId: string) {
    this.polygons = polygons;
    this.category = category;
    this.bbox = getBBox(this.polygons);
    this.points = toSvgPoints(this.polygons);
    this.bimId = bimId;

    this.width = this.bbox.x2 - this.bbox.x1;
    this.height = this.bbox.y2 - this.bbox.y1;
  }
}
