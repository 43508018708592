import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossiersRoutingModule } from './dossiers-routing.module';
import { DossierDetailComponent } from './views/dossier-detail/dossier-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SecurePipe } from './pipes/secure-pipe';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { TimelineEventComponent } from './views/dossier-detail/timeline-event/timeline-event.component';
import { InspectionResultComponent } from './views/dossier-detail/inspection-result/inspection-result.component';
import { InspectionTemplatesComponent } from './views/inspection-templates/inspection-templates.component';
import { InspectionTemplateDetailComponent } from './views/inspection-template-detail/inspection-template-detail.component';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { SectionComponent } from './components/section/section.component';
import { SectionItemComponent } from './components/section-item/section-item.component';
import { WorkspacesModule } from '../workspaces-module/workspaces.module';
import { OpenQuestionComponent } from './components/open-question/open-question.component';
import { ImagesQuestionComponent } from './components/images-question/images-question.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { ChecklistComponent } from './components/checklist/checklist.component';

@NgModule({
  declarations: [
    DossierDetailComponent,
    SecurePipe,
    TimelineEventComponent,
    InspectionResultComponent,
    InspectionTemplatesComponent,
    InspectionTemplateDetailComponent,
    SectionComponent,
    SectionItemComponent,
    OpenQuestionComponent,
    ImagesQuestionComponent,
    ToolboxComponent,
    ChecklistComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    DossiersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild(),
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    WorkspacesModule,
  ],
  providers: [{ provide: OWL_DATE_TIME_LOCALE, useValue: 'nl' }],
})
export class DossiersModule {}
