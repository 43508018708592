<div cdkVirtualScrollingElement class="dossier-detail-page styled-scroll">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>
      <h1 class="h4">{{ 'dossier-page' | translate }}</h1>
      <h2 class="location-title">{{ buildingName }}</h2>
    </div>

    <div class="header-bar__search">
      <button (click)="uploadButton.click()" class="button-secondary button--has-icon">
        <span class="material-symbols-rounded">add</span>
        {{ 'upload-ifc-model' | translate }}
      </button>
    </div>
  </div>

  <div class="dossier-header">
    <h1 class="h6">{{ 'activity-overview' | translate }}</h1>

    <div class="dossier-header__filtering">
      <div class="select datetime">
        <input
          [owlDateTime]="fromto"
          [owlDateTimeTrigger]="fromto"
          [selectMode]="'range'"
          placeholder="{{ 'filter-on-date' | translate }}"
          (dateTimeChange)="setFromTo($event.value)"
          (keydown)="$event.preventDefault()"
          [formControl]="dateTime" />
        <owl-date-time [pickerType]="'calendar'" #fromto></owl-date-time>

        <button (click)="clearFromTo()" class="clear-button" *ngIf="filterModel.from">
          <span class="material-symbols-rounded">clear</span>
        </button>
      </div>
      <div class="select type">
        <select [ngModel]="filterModel.timeLineType" (ngModelChange)="setTimeLineType($event)">
          <option selected [ngValue]="undefined">{{ 'activity' | translate }}</option>
          <option [ngValue]="timeLineType" *ngFor="let timeLineType of timeLineTypes">
            {{ timeLineType | translate }}
          </option>
        </select>
      </div>
    </div>

    <div class="dossier-header__add">
      <div class="growing-textarea">
        <textarea
          #noteInput
          name="text"
          (keyup)="(noteInput.value)"
          onInput="this.parentNode.dataset.replicatedValue = this.value;"
          placeholder="{{ 'quick-add-note' | translate }}"
          rows="1"></textarea>

        <button
          (click)="postNoteEvent()"
          class="send-button"
          [class.visible]="noteInput.value.length > 0">
          <span class="material-symbols-rounded">send</span>
        </button>
      </div>
    </div>
  </div>

  <cdk-virtual-scroll-viewport class="timeline" itemSize="146">
    <div
      *cdkVirtualFor="let item of ds; let last = last"
      class="timeline-event"
      tabindex="0"
      (click)="onClickTimelineEvent(item)"
      (keyup)="onClickTimelineEvent(item)">
      <app-timeline-event
        [item]="item"
        (imageFunction)="openImage(item, $event)"
        [lastItem]="last"></app-timeline-event>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<app-page-slider [menuExpanded]="menuExpanded">
  <div *ngIf="!selectedTimelineItem" class="dossier-specs__text">
    {{ 'dossier-content-area' | translate }}
  </div>

  <div
    *ngIf="selectedTimelineItem"
    [ngSwitch]="selectedTimelineItem.item_type"
    class="dossier-specs">
    <div *ngSwitchCase="TimeLineType.Note" class="dossier-specs__text">
      <div>{{ previouslySelectedNote?.rich_text }}</div>
    </div>

    <div *ngSwitchCase="TimeLineType.ConstructionReport" class="dossier-specs__text">
      <div>{{ previouslySelectedConstructionReport?.description }}</div>
    </div>

    <div *ngSwitchCase="TimeLineType.BimLink" class="dossier-specs__text">
      <p>{{ 'open-3d-model-text' | translate }}</p>
      <a [routerLink]="['..', 'model']" class="button">{{ 'open-model' | translate }}</a>
    </div>

    <ng-container *ngIf="selectedFile !== undefined">
      <app-file-specs
        *ngSwitchCase="TimeLineType.ConstructionReport"
        [timeStamp]="selectedTimelineItem.timestamp_utc"
        [user]="previouslySelectedName"
        [fileUrl]="selectedFileUrl"
        [file]="selectedFile"></app-file-specs>

      <app-file-specs
        *ngSwitchCase="TimeLineType.Note"
        [timeStamp]="selectedTimelineItem.timestamp_utc"
        [user]="previouslySelectedName"
        [fileUrl]="selectedFileUrl"
        [file]="selectedFile"></app-file-specs>
    </ng-container>
    <app-inspection-result
      *ngSwitchCase="TimeLineType.Inspection"
      [timeStamp]="selectedTimelineItem.timestamp_utc"
      [user]="previouslySelectedName"
      [item]="selectedTimelineItem"></app-inspection-result>
  </div>
</app-page-slider>

<input
  id="uploadButton"
  #uploadButton
  (change)="uploadIfc($event)"
  (click)="$event.stopPropagation()"
  accept=".ifc,.zip"
  class="upload-button"
  type="file" />
