<div class="section-item_drag_handle_container">
  <ng-content></ng-content>
</div>
<app-workspace-open-question
  *ngIf="item.isQuestion() && item.questionType === QuestionType.OpenQuestion"
  #child
  [question]="item"
  [edit]="edit"
  [first]="first"
  [last]="last"
  (move)="move.emit($event)"
  (delete)="deleteChild.emit(item)"></app-workspace-open-question>
<app-workspace-checklist
  *ngIf="item.isQuestion() && item.questionType === QuestionType.MultipleChoice"
  #child
  [question]="item"
  [edit]="edit"
  [first]="first"
  [last]="last"
  (move)="move.emit($event)"
  (delete)="deleteChild.emit(item)"></app-workspace-checklist>
<app-workspace-file-upload
  *ngIf="item.isFileRequest()"
  #child
  [fileRequest]="item"
  [edit]="edit"
  [first]="first"
  [last]="last"
  (move)="move.emit($event)"
  (delete)="deleteChild.emit(item)"></app-workspace-file-upload>
