import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildingMenuService {
  private buildingMenuStatus = new BehaviorSubject<{ active: boolean; expand: boolean }>({
    active: false,
    expand: true,
  });

  public buildingMenuStatus$ = this.buildingMenuStatus.asObservable();

  show(): void {
    this.buildingMenuStatus.next({ active: true, expand: this.buildingMenuStatus.value.expand });
  }

  hide(): void {
    this.buildingMenuStatus.next({ active: false, expand: this.buildingMenuStatus.value.expand });
  }

  toggleExpand(): void {
    this.buildingMenuStatus.next({
      active: this.buildingMenuStatus.value.active,
      expand: !this.buildingMenuStatus.value.expand,
    });
  }

  status(): boolean {
    return this.buildingMenuStatus.value.active;
  }
}
