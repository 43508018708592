import { Component } from '@angular/core';
import { Context, ContextPopupService } from './context-popup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Property } from '../model-viewer.component';
import { ModelViewerEditingService } from '../model-viewer-editing-service';
import { ProductsAddPopupService } from '../products-add-popup/products-add-popup.service';
import { Product } from '@api-clients/product';
import { BimElement, ObjectCategory, RoomCategory } from '@api-clients/bim';
import { isBimObject, isBimRoom } from '../utils/types';

@Component({
  selector: 'app-context-popup',
  templateUrl: './context-popup.component.html',
  styleUrls: ['./context-popup.component.scss'],
})
export class ContextPopupComponent {
  protected context: Context;
  protected elementProperties?: Record<string, Property>;
  protected element?: BimElement;
  protected categoryEdit: boolean = false;
  protected categories: string[] = [];
  protected selectedCategory?: string;
  protected categoryChanged: boolean = false;
  protected anyPropertyChanged: boolean = false;

  constructor(
    private readonly contextService: ContextPopupService,
    private readonly productsAddPopupService: ProductsAddPopupService,
    private readonly modelViewerEditingService: ModelViewerEditingService
  ) {
    this.context = contextService.context;
    contextService.contextChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.context = value;
    });
    this.modelViewerEditingService.elementProperties
      .pipe(takeUntilDestroyed())
      .subscribe((next) => {
        this.elementProperties = next;
      });
    this.modelViewerEditingService.element.pipe(takeUntilDestroyed()).subscribe((next) => {
      this.element = next;
      this.categoryChanged = false;
      this.categoryEdit = false;
      this.anyPropertyChanged = false;
      if (isBimRoom(next)) {
        this.categories = Object.keys(RoomCategory);
        this.selectedCategory = next.category;
      } else if (isBimObject(next)) {
        this.categories = Object.keys(ObjectCategory);
        this.selectedCategory = next.category;
      }
    });
  }

  protected closePopup(): void {
    this.contextService.hide();
  }

  protected inputForType(type: string): string {
    switch (type) {
      case 'float':
        return 'number';
      default:
        return 'text';
    }
  }

  protected addProperties(): void {
    if (!this.elementProperties) return;
    void this.modelViewerEditingService.addPropertiesToShoppingCart(this.elementProperties);
  }

  protected editCategory(): void {
    this.categoryEdit = true;
  }

  protected selectCategory(category: string): void {
    if (!this.element) return;
    this.selectedCategory = category;
    this.categoryChanged = true;

    if (isBimRoom(this.element)) {
      this.element.category = RoomCategory[category];
    } else if (isBimObject(this.element)) {
      this.element.category = ObjectCategory[category];
    }

    this.categoryEdit = false;
  }

  protected propertyChanged(): void {
    this.anyPropertyChanged = true;
  }

  protected productPopup(): void {
    this.productsAddPopupService.show(
      (<Product[]>this.elementProperties?.['products']?.value ?? []).map((p) => p.gtin) ?? []
    );
  }

  protected addProducts(productsToAdd: []): void {
    this.modelViewerEditingService.addProducts(productsToAdd);
  }

  protected removeProduct(index: number): void {
    //not happy with if statement, but it is necessary to prevent undefined errors
    if (this.elementProperties?.['products']?.value) {
      (<Product[]>this.elementProperties['products'].value).splice(index, 1);
    } else {
      console.error('elementProperties or its nested properties are undefined');
    }
  }
}
